import React, { useEffect } from "react";
import { Grid, Stack, Typography, Box } from "@mui/material";
import CustomBreadCrumbs from "../../components/CustomBreadCrumbs";
import Constants from "../../config/Constants";
import CustomLink from "../../components/CustomLink";

function Terms(props: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ maxWidth: 900, margin: "auto", p: 2 }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "0px important",
            paddingLeft: 0,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              //marginLeft: "34px",
              marginTop: "70px",
            }}
          >
            <CustomBreadCrumbs
              screenType={Constants.screenType.terms}
              currentLinkText={"Terms"}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            Qrade Terms and Conditions
          </Typography>
          <Box>
            <Typography variant="body2" gutterBottom>
              These General User Terms constitute a binding agreement between
              Qrade, and its Affiliates, and all users accessing our digital
              platforms. The terms outline the conditions for using our Sites
              and Services, with a specific focus on governing platform
              interactions and participation in our Reviews Program. By
              accessing our digital ecosystem, users acknowledge and agree to
              the comprehensive guidelines that define acceptable use, user
              responsibilities, and platform engagement protocols.
            </Typography>

            <Typography
              sx={{ marginTop: "15px", marginBottom: "10px" }}
              variant="body2"
              gutterBottom
            >
              BY ACCESSING AND USING QRADE WEBSITES, WRITING REVIEWS AND USING
              QRADE SERVICES, YOU CONSENT THAT YOU HAVE READ, UNDERSTOOD AND
              AGREED TO BE LEGALLY BOUND BY THESE GENERAL USER TERMS. IF YOU DO
              NOT AGREE WITH THESE TERMS, YOU MUST DISCONTINUE USING THIS
              WEBSITE AND OUR SERVICES.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              Use of Website
            </Typography>

            <Typography variant="body2" gutterBottom>
              By accessing and using Qrade, you agree to comply with all
              applicable laws and regulations, and you acknowledge that you are
              responsible for your conduct on the site. You may not use Qrade
              for any unlawful purposes or in a manner that could damage,
              disable, or impair the site or interfere with any other party's
              use of the site.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              Reviews and Content:
            </Typography>
            <Typography variant="body2" gutterBottom>
              By submitting reviews or content to Qrade, you agree to the
              following terms:
            </Typography>
            <Box>
              <Typography variant="h6" gutterBottom>
                License:
              </Typography>
              <Typography variant="body2" gutterBottom>
                You grant Qrade a non-exclusive, worldwide, royalty-free license
                to use, modify, display, and distribute your content on our
                platform.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Ownership:
              </Typography>
              <Typography variant="body2" gutterBottom>
                You retain responsibility for the reviews submitted by you, the
                user. But allow Qrade to use it as described in these terms.
                Qrade can use any submitted reviews for promotional use, data
                mining, research and sublicensing.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Perpetual License:
              </Typography>
              <Typography variant="body2" gutterBottom>
                The rights you grant are non-exclusive, worldwide, royalty-free,
                and perpetual.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Accuracy:
              </Typography>
              <Typography variant="body2" gutterBottom>
                You are responsible for ensuring the accuracy and truthfulness
                of your reviews and content.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Editing:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Qrade reserves the right to edit reviews for clarity and
                readability without altering the overall meaning.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Public Display:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Your reviews may be publicly visible, including your first name
                and last initial, unless you choose to post anonymously.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Removal:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Qrade may remove content that violates our policies or
                applicable laws.
              </Typography>
              <Typography variant="h6" gutterBottom>
                No Compensation:
              </Typography>
              <Typography variant="body2" gutterBottom>
                You will not receive compensation for your reviews or content.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Prohibited Content:
              </Typography>
              <Typography variant="body2" gutterBottom>
                You agree not to submit content that is unlawful, defamatory,
                abusive, obscene, or infringes on others' rights.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Third-Party Use:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Qrade may share your content with third parties for business
                purposes.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Indemnification:
              </Typography>
              <Typography variant="body2" gutterBottom>
                You agree to indemnify Qrade against claims arising from your
                content.
              </Typography>

              <Typography variant="body2" gutterBottom>
                By using Qrade, you acknowledge and accept these terms for
                reviews and content submission.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              User Accounts
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Registration:
            </Typography>
            <Typography variant="body2" gutterBottom>
              To create an account on Qrade, you must provide accurate and
              complete information.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Account Security:
            </Typography>
            <Typography variant="body2" gutterBottom>
              You are responsible for maintaining the confidentiality of your
              account credentials and all activities that occur under your
              account.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              One Account Per User:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Each user is limited to one account. Multiple accounts for a
              single user are prohibited.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Account Termination:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Qrade reserves the right to suspend or terminate accounts that
              violate our terms or for any other reason at our discretion.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Information Updates:
            </Typography>
            <Typography variant="body2" gutterBottom>
              You agree to promptly update your account information if any
              changes occur.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Age Restriction:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Users must be at least 18 years old to create an account.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Commercial Use:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Accounts created for commercial purposes must be clearly
              identified as such.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Account Sharing:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Sharing your account credentials with others is strictly
              prohibited.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Inactive Accounts:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Qrade may close accounts that remain inactive for an extended
              period.
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Data Retention:
            </Typography>
            <Typography variant="body2" gutterBottom>
              We may retain your account information for a reasonable time after
              account closure for legal and operational purposes.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              Copyright and Trademarks
            </Typography>
            <Typography variant="body2" gutterBottom>
              All content on Qrade, including but not limited to text, graphics,
              logos, and software, is the property of Qrade or its content
              suppliers and is protected by copyright laws. The Qrade name and
              logo are trademarks of Qrade. Users may not use, reproduce, or
              distribute any Qrade trademarks without prior written permission.
              Reviews submitted by users remain the property of their respective
              authors. By posting a review, users grant Qrade a non-exclusive,
              worldwide, royalty-free license to use, reproduce, and display the
              review on our platform. Qrade reserves the right to edit reviews
              for clarity and readability. Users may not use copyrighted
              material from Qrade without permission. Fair use of Qrade
              trademarks is permitted for referencing our services, provided it
              does not imply endorsement or affiliation. Any unauthorized use of
              Qrade's intellectual property may constitute infringement. Qrade
              respects the intellectual property rights of others. We will
              respond to notices of alleged copyright infringement in accordance
              with applicable laws.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              LIABILITY DISCLAIMER
            </Typography>

            <Typography
              sx={{ marginTop: "5px", marginBottom: "10px" }}
              variant="body2"
              gutterBottom
            >
              QRADE, INCLUDING ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND
              AFFILIATES, WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
              INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING FROM YOUR
              ACCESS TO OR USE OF THIS SITE.
            </Typography>

            <Typography variant="body2" gutterBottom>
              To the fullest extent permitted by applicable law, Qrade and its
              associated parties disclaim all warranties, express or implied,
              including but not limited to:
            </Typography>

            <Box sx={{ marginLeft: "25px" }}>
              <Typography variant="body2" gutterBottom>
                a. The accuracy, reliability, or completeness of the site's
                content
              </Typography>
              <Typography variant="body2" gutterBottom>
                b. The availability or uninterrupted operation of the site
              </Typography>
              <Typography variant="body2" gutterBottom>
                c. The security of the site or the absence of viruses or other
                harmful components
              </Typography>
              <Typography variant="body2" gutterBottom>
                d. Any damages resulting from the use of information obtained
                through the site
              </Typography>
            </Box>

            <Typography variant="body2" gutterBottom>
              Your use of Qrade is at your own risk. Qrade does not guarantee
              the accuracy of reviews or other user-generated content. We are
              not responsible for any decisions you make based on information
              found on our site.
            </Typography>
            <Typography variant="body2" gutterBottom>
              In no event shall Qrade's total liability to you for all damages,
              losses, and causes of action exceed the amount paid by you, if
              any, for accessing our site.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Some jurisdictions do not allow the exclusion of certain
              warranties or the limitation of liability for consequential or
              incidental damages, so the above limitations may not apply to you.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              Data Protection
            </Typography>
            <Typography variant="body2" gutterBottom>
              Users commit to adhering to applicable data protection laws and
              our{" "}
              <CustomLink
                underline="hover"
                key="2"
                color="#7986cb"
                to={"/privacy"}
              >
                Privacy Policy
              </CustomLink>{" "}
              in fulfilling their respective obligations under these General
              User Terms. Our Privacy Policy outlines our practices for
              collecting, utilizing, and protecting your personal information
              (referred to as "Information") to manage your experience on our
              Services and Site. This mutual commitment ensures transparency in
              data handling and reinforces our dedication to safeguarding user
              privacy while providing a seamless platform experience.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              If you have questions or requests regarding the Terms and
              Conditions, please contact us at:{" "}
              <a href="mailto: qrade9@gmail.com">email</a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Terms;
