import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  Rating,
  Chip,
  Avatar,
  Pagination,
  Box,
} from "@mui/material";
import { deepOrange, blueGrey } from "@mui/material/colors";
import styles from "./MainContainer.module.css";
import "./MainContainer.module.css";
import Container from "@mui/material/Container";
import { Height, Margin } from "@mui/icons-material";
import AnchorIcon from "@mui/icons-material/Anchor";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  coreStatus,
  coreMessage,
  companyList,
  getCompanies,
} from "../../../redux/companySlice";
import CustomSearch from "../../../components/CustomSearch";
import {
  setPreviousRoutes,
  clearPreviousRoutes,
  apiToken,
  apiInitialized,
  tokenExpired,
  setTokenExpired,
} from "../../../redux/userSlice";
import {
  GetCompanyInterface,
  AlertState,
} from "../../../interfaces/company.interfaces";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../../interfaces/company.interfaces";
import CompanyCard from "./CompanyCard";
import NavigationBar from "./NavigationBar";
import { purple } from "@mui/material/colors";
import CustomSnackbar from "../../../components/CustomSnackBar";

const componentStyles = {
  titleCard: {
    padding: 2,
    display: "flex",
    flexDirection: "column",
    marginTop: 9,
    backgroundColor: "var(--primaryColor)",
  },
  titleTypography: {
    paddingTop: 0,
    color: "black",
    textAlign: "center",
    alignContent: "center",
  },
  listContainer: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    minHeight: 150,
    backgroundColor: "white",
  },
  content: {
    //display: "flex",
    //alignItems: "center",
    minWidth: 275,
    minHeight: 380,
    backgroundColor: "white",
    border: 1,
    marginBottom: 1,
  },
  logoIcon: {
    marginLeft: 2,
    marginTop: 2,
    marginBottom: 1,
    //marginRight: 1,
  },
  // name: {
  //   fontWeight: "bold",
  //   marginLeft: 1,
  //   marginTop: 2,
  //   marginBottom: 1,
  //   "&:hover": {
  //     cursor: "pointer", // Pointer cursor on hover
  //     color: "primary.main",
  //   },
  // },
  // website: {
  //   marginLeft: 1,
  //   marginTop: 2,
  //   marginBottom: 1,
  // },
  // websiteButton: {
  //   width: 80,
  //   backgroundColor: purple[700],
  //   borderColor: "black",
  //   color: "white",
  //   //borderRadius: "4px",
  //   "&:hover": {
  //     backgroundColor: "#0062cc",
  //   },
  // },
  // profileButton: {
  //   minWidth: 80,
  // },
  chipContainer: {
    flexWrap: "wrap",
    maxWidth: 500,
  },
  // companyAvatar: {
  //   bgcolor: blueGrey[700],
  //   backgroundSize: "cover",
  //   backgroundPosition: "top center",
  //   border: 1,
  //   "& img": {
  //     objectFit: "contain", // Ensures the image fits well inside the avatar
  //   },
  // },
  // companyCardContainer: {
  //   //display: "flex",
  //   //alignItems: "center",
  //   minWidth: 275,
  //   minHeight: {
  //     xs: 380,
  //     sm: 220,
  //     md: 220,
  //     lg: 220,
  //   },
  //   backgroundColor: "white",
  //   border: 1,
  //   marginBottom: 1,
  // },
  categoryChip: {
    minWidth: 100,
    //width: 100,
    //margin: "1px",
    marginBottom: 1,
    marginRight: 1,
    backgroundColor: "#f6e2ed", // blueGrey[200],
    fontSize: 11,
    fontWeight: "bold",
    color: "black",
    borderRadius: "5px",
  },
  serviceChip: {
    minWidth: 100,
    width: 100,
    marginBottom: 1,
    marginRight: 1,
    borderRadius: "5px",
    fontSize: 11,
    fontWeight: "bold",
    color: "black",
  },
  search: {
    paddingLeft: "15px",
  },
  navigation: {
    // paddingLeft: "15px",
    // width: "150px",
    // height: "105px",
  },
  noDataContent: {
    alignContent: "center",
    TextAlign: "center",
    alignText: "center",
    minWidth: 275,
    minHeight: 80,
    backgroundColor: "inherit",
    border: 1,
    marginBottom: 1,
  },
};

function MainContainer(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyListData = useAppSelector(companyList);
  const isApiInitialized = useAppSelector(apiInitialized);
  const apiTokenKey = useAppSelector(apiToken);
  const tokenStatusExpired = useAppSelector(tokenExpired);

  //const companyListData = useAppSelector(companyList);
  // const { selectedValue, onValueSelect } =
  //   useOutletContext<OutletContextType>();

  const [selectedValue, setSelectedValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSizeLimit, setPageSizeLimit] = React.useState(10);
  const [sortByField, setSortByField] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");

  const [searchText, setSearchText] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState(selectedValue);

  const [snackBarState, setSnackBarState] = React.useState<AlertState>({
    open: false,
    message: "Info",
    severity: "success",
  });

  useEffect(() => {
    if (tokenStatusExpired === "true") {
      setSnackBarState({
        ...snackBarState,
        open: true,
        message:
          "For your security, you have been automatically logged out due to inactivity. Please log in again to continue.",
        severity: "success",
      });
      //dispatch(setTokenExpired("false"));
    }
  }, [tokenStatusExpired]);

  useEffect(() => {
    try {
      if (isApiInitialized) {
        let params: GetCompanyInterface = {
          apiKey: apiTokenKey,
          page: currentPage,
          pageSize: pageSizeLimit,
          sortBy: sortByField,
          orderByField: orderBy,
          search: searchText,
          category: selectedValue ? selectedValue : "",
        };
        dispatch(getCompanies(params));
        dispatch(clearPreviousRoutes("all"));
        dispatch(setPreviousRoutes({ path: "/", type: "landing" }));
      }
    } catch (e) {}
  }, [isApiInitialized]);

  useEffect(() => {
    try {
      if (isApiInitialized && selectedValue) {
        let params: GetCompanyInterface = {
          apiKey: apiTokenKey,
          page: currentPage,
          pageSize: pageSizeLimit,
          sortBy: sortByField,
          orderByField: orderBy,
          search: "",
          category:
            selectedValue && selectedValue != "All" ? selectedValue : "",
        };
        dispatch(getCompanies(params));
      }
    } catch (e) {}
  }, [selectedValue]);

  const handleValueSelect = (value: string) => {
    setSelectedValue(value);
  };

  const handleCategoryClick = (value: string) => {
    setSelectedCategory(value);
  };

  const onPageChange = (event: any, value: any) => {
    setCurrentPage(value);
    let params: GetCompanyInterface = {
      apiKey: apiTokenKey,
      page: value,
      pageSize: pageSizeLimit,
      sortBy: sortByField,
      orderByField: orderBy,
      search: searchText,
      category: selectedCategory ? selectedCategory : "",
    };
    dispatch(getCompanies(params));
    event.preventDefault();
  };

  const onSearchChange = (event: any, value: any) => {
    //onValueSelect("");
    setSearchText("");
    let params: GetCompanyInterface = {
      apiKey: apiTokenKey,
      page: currentPage,
      pageSize: pageSizeLimit,
      sortBy: sortByField,
      orderByField: orderBy,
      search: value,
      category: "",
    };
    dispatch(getCompanies(params));
    event.preventDefault();
  };

  const handleDetailsClick = (linkUrl: string) => {
    dispatch(
      setPreviousRoutes({ path: `/company/${linkUrl}`, type: "companydetails" })
    );
    navigate(`/company/${linkUrl}`);
  };

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    dispatch(setTokenExpired("false"));
    setSnackBarState({ ...snackBarState, open: false });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CustomSnackbar
          open={snackBarState.open}
          handleClose={handleSnackBarClose}
          message={snackBarState.message}
          severity={snackBarState.severity}
        />
        <Card sx={componentStyles.titleCard}>
          <Typography
            sx={componentStyles.titleTypography}
            variant="h3"
            component="div"
          >
            Find the right consulting company for any contract roles
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div style={componentStyles.navigation}>
          <NavigationBar
            onValueSelect={handleValueSelect}
            selectedValue={selectedValue}
          />
        </div>
        <div key={"searchdiv1"} style={componentStyles.search}>
          <CustomSearch onSearchChange={onSearchChange} />
        </div>
        <Paper sx={componentStyles.listContainer}>
          {companyListData &&
          companyListData.data &&
          companyListData.data.length > 0 ? (
            companyListData.data.map((company: any) => {
              const dataCategory = company.category
                ? company.category.split(",")
                : [];
              return (
                <div key={company.name}>
                  <Card
                    key={company.name}
                    sx={{
                      minWidth: 275,
                      minHeight: {
                        xs: 380,
                        sm: 220,
                        md: 220,
                        lg: 220,
                      },
                      backgroundColor: "white",
                      border: 1,
                      marginBottom: 1,
                    }}
                  >
                    <Stack spacing={{ xs: 0, sm: 0 }}>
                      <Grid container spacing={0}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          //className={styles.companyNameContainer}
                          sx={{
                            fontWeight: "bold",
                            paddingLeft: { xs: "25px", sm: "27px" },
                            //marginLeft: 1,
                            marginTop: "20px",
                            marginLeft: "0px !important",
                            //marginBottom: 1,
                            "&:hover": {
                              cursor: "pointer", // Pointer cursor on hover
                              color: "primary.main",
                            },
                          }}
                          onClick={() => handleDetailsClick(company.short_code)}
                        >
                          <Box display="flex" alignItems="center">
                            <Avatar
                              sx={{
                                bgcolor: blueGrey[700],
                                backgroundSize: "cover",
                                backgroundPosition: "top center",
                                border: 1,
                                "& img": {
                                  objectFit: "contain", // Ensures the image fits well inside the avatar
                                },
                              }}
                              variant="rounded"
                              key={company.name}
                              src={company.logo ? company.logo : ""}
                            >
                              {company.name && company.name.substring(0, 1)}
                            </Avatar>
                            <Box sx={{ pl: "15px", mt: "10px" }}>
                              {company.name} <br />
                              <Rating
                                name="read-only"
                                value={company.averagerate}
                                readOnly
                                precision={0.5}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          //direction="row"
                          //spacing={2}
                          // justifyContent="center"
                          // alignItems="center"
                          //className={styles.companyButtonContainer}
                          sx={{
                            marginLeft: { xs: "27px", sm: 1 },
                            //marginTop: 2,
                            marginTop: { xs: "10px", sm: "30px" },
                            marginBottom: 1,
                            minWidth: "250px",
                          }}
                        >
                          <>
                            <Button
                              sx={{
                                minWidth: "20px",
                              }}
                              variant="outlined"
                              //size="large"
                              key={company.profileButton}
                              onClick={() =>
                                handleDetailsClick(company.short_code)
                              }
                            >
                              {"Company Details"}
                            </Button>
                            <Button
                              //className={styles.companyButton}
                              sx={{
                                paddingLeft: { xs: "25px" },
                                marginLeft: "10px",
                                width: "80px",
                                backgroundColor: purple[700],
                                borderColor: "black",
                                color: "white",
                                //borderRadius: "4px",
                                "&:hover": {
                                  backgroundColor: "#0062cc",
                                },
                              }}
                              variant="outlined"
                              //size="large"
                              key={company.website}
                              href={company.website}
                              target="_blank"
                            >
                              {"Website"}
                            </Button>
                          </>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" spacing={0}>
                      <Stack className={styles.companyDetailsContainer}>
                        <Typography
                          component="div"
                          variant="body2"
                          gutterBottom
                        >
                          {company.details}
                        </Typography>
                      </Stack>
                    </Stack>
                    {company.state && (
                      <Stack direction="row" spacing={0}>
                        <Stack>
                          <Typography
                            component="div"
                            variant="body2"
                            gutterBottom
                          >
                            <div className={styles.locationContainer}>
                              <LocationOnIcon /> {company.city}, {company.state}
                            </div>
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                    {/* <Stack direction="row" spacing={0}> */}
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={componentStyles.chipContainer}
                      className={styles.companyCategoryContainer}
                    >
                      {company.category &&
                        company.category.split(",").map((value: any) => {
                          return (
                            <Chip
                              sx={componentStyles.categoryChip}
                              label={value}
                              key={value}
                              size="small"
                              //color="warning"
                              //variant="outlined"
                            />
                          );
                        })}
                      {company.services &&
                        company.services.split(",").map((value: any) => {
                          return (
                            <Chip
                              sx={componentStyles.serviceChip}
                              key={value}
                              label={value}
                              size="small"
                              color="primary"
                              variant="outlined"
                            />
                          );
                        })}
                    </Stack>
                    {/* <Stack direction="row" spacing={1}>
                        {company.services &&
                          company.services.split(",").map((value: any) => {
                            return (
                              <Chip
                                sx={componentStyles.chip}
                                key={value}
                                label={value}
                                size="small"
                                color="primary"
                                variant="outlined"
                              />
                            );
                          })}
                      </Stack> */}
                    {/* </Stack> */}
                  </Card>
                </div>
              );
            })
          ) : (
            <div style={componentStyles.noDataContent}>
              {" "}
              Your search did not match any company name.{" "}
            </div>
          )}

          {companyListData &&
            companyListData.data &&
            companyListData.data.length > 0 && (
              <Stack spacing={2}>
                <Pagination
                  count={
                    companyListData.pagination &&
                    companyListData.pagination.lastPage
                  }
                  onChange={onPageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MainContainer;
