import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Constants from "../config/Constants";
import CustomLink from "../components/CustomLink";

export default function CustomBreadCrumbs(props: any) {
  const { screenType, currentLinkText, companyCode } = props;

  const breadcrumbsCompanyHome = [
    <Typography key="1" sx={{ color: "text.primary" }}>
      Home
    </Typography>,
  ];

  const breadcrumbsCompanyDetails = [
    <CustomLink underline="hover" key="1" color="#7986cb" to={"/"}>
      Home
    </CustomLink>,
    <Typography key="2" sx={{ color: "#616161" }}>
      {currentLinkText}
    </Typography>,
  ];

  const breadcrumbsCompanyNew = [
    <CustomLink underline="hover" key="1" color="#7986cb" to={"/"}>
      Home
    </CustomLink>,
    <Typography key="2" sx={{ color: "#616161" }}>
      {currentLinkText}
    </Typography>,
  ];

  const breadcrumbsCompanyEdit = [
    <CustomLink underline="hover" key="1" color="#7986cb" to={"/"}>
      Home
    </CustomLink>,
    <Typography key="2" sx={{ color: "#616161" }}>
      {currentLinkText}
    </Typography>,
  ];

  const breadcrumbsCompanyEditReview = [
    <CustomLink underline="hover" key="1" color="#7986cb" to={"/"}>
      Home
    </CustomLink>,
    <CustomLink
      underline="hover"
      key="2"
      color="#7986cb"
      to={`/company/${companyCode}`}
    >
      {companyCode}
    </CustomLink>,
    <Typography key="3" sx={{ color: "#616161" }}>
      {currentLinkText}
    </Typography>,
  ];

  const breadcrumbsPrivacy = [
    <CustomLink underline="hover" key="1" color="#7986cb" to={"/"}>
      Home
    </CustomLink>,
    <Typography key="2" sx={{ color: "#616161" }}>
      {currentLinkText}
    </Typography>,
    <CustomLink underline="hover" key="1" color="#7986cb" to={"/terms"}>
      Terms
    </CustomLink>,
  ];

  const breadcrumbsTerms = [
    <CustomLink underline="hover" key="1" color="#7986cb" to={"/"}>
      Home
    </CustomLink>,
    <Typography key="2" sx={{ color: "#616161" }}>
      {currentLinkText}
    </Typography>,
    <CustomLink underline="hover" key="1" color="#7986cb" to={"/privacy"}>
      Privacy
    </CustomLink>,
  ];

  const renderContent = (screenType: string) => {
    switch (screenType) {
      case Constants.screenType.companyHome:
        return breadcrumbsCompanyHome;
      case Constants.screenType.companyDetails:
        return breadcrumbsCompanyDetails;
      case Constants.screenType.companyNew:
        return breadcrumbsCompanyNew;
      case Constants.screenType.companyEdit:
        return breadcrumbsCompanyEdit;
      case Constants.screenType.companyReviewEdit:
        return breadcrumbsCompanyEditReview;
      case Constants.screenType.privacy:
        return breadcrumbsPrivacy;
      case Constants.screenType.terms:
        return breadcrumbsTerms;
      default:
        return breadcrumbsCompanyHome;
    }
  };

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {renderContent(screenType)}
      {/* {breadcrumbsCompanyHome} */}
    </Breadcrumbs>
  );
}
