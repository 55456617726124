import React, { useEffect } from "react";
import { Grid, Stack, Typography, Box } from "@mui/material";
import CustomBreadCrumbs from "../../components/CustomBreadCrumbs";
import Constants from "../../config/Constants";

function Privacy(props: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ maxWidth: 900, margin: "auto", p: 2 }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "0px important",
            paddingLeft: 0,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              //marginLeft: "34px",
              marginTop: "70px",
            }}
          >
            <CustomBreadCrumbs
              screenType={Constants.screenType.privacy}
              currentLinkText={"Privacy"}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            Qrade Privacy Policy
          </Typography>
          <Typography variant="body1" gutterBottom>
            This Privacy Policy outlines how Qrade collects, manages, stores,
            processes, and shares your personal data when you access our website
            (www.qrade.co and its subdomains, collectively referred to as the
            “Site”) or interact with related communications, such as emails,
            phone calls, and text messages (collectively referred to as the
            “Service”). By accessing or using our Site or Service, you
            acknowledge and agree to the collection, use, disclosure, and
            retention of your personal information as detailed in this policy.
            If you are uncomfortable sharing your personal information, having
            it displayed on our Site, or its use as described, we recommend
            refraining from using our Service. References to “we,” “us,” and
            “our” refer to Qrade.
          </Typography>
          <Typography variant="h5" gutterBottom>
            1. Personal Information We Collect
          </Typography>
          <Box>
            <Typography variant="h6" gutterBottom>
              Identifiers
            </Typography>
            <Typography variant="body2" gutterBottom>
              Name, email address, postal address, phone number, IP address,
              account username
            </Typography>
            <Typography variant="h6" gutterBottom>
              Demographic information
            </Typography>
            <Typography variant="body2" gutterBottom>
              Age, gender, location
            </Typography>
            <Typography variant="h6" gutterBottom>
              Financial information
            </Typography>
            <Typography variant="body2" gutterBottom>
              Payment details, purchase history
            </Typography>
            <Typography variant="h6" gutterBottom>
              Technical data
            </Typography>
            <Typography variant="body2" gutterBottom>
              Device information, browser type, operating system
            </Typography>
            <Typography variant="h6" gutterBottom>
              Usage data
            </Typography>
            <Typography variant="body2" gutterBottom>
              Browsing history, search queries, interactions with our
              website/app
            </Typography>
            <Typography variant="h6" gutterBottom>
              Communication data:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Customer support interactions, survey responses
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              2. Sources of Personal Data Collection
            </Typography>
            <Typography variant="body1" gutterBottom>
              We gather personal data through multiple channels:
            </Typography>
            <Box>
              <Typography variant="h6" gutterBottom>
                Direct Collection
              </Typography>
              <Typography variant="body2" gutterBottom>
                We collect information directly from you when you: Visit our
                website, Create a registered user account, Complete online forms
                and Voluntarily provide personal details during interactions.
              </Typography>

              <Typography variant="h6" gutterBottom>
                Automated Collection
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                As you navigate our platform, we automatically capture data
                through:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Cookies and tracking technologies, Technical information
                including: IP address, Device specifications Operating system,
                Browser type and Usage patterns and interactions.
              </Typography>

              <Typography variant="body2" color="text.secondary" gutterBottom>
                Note: Not all collected data constitutes personal information.
                Some cookies are essential for website functionality and
                performance.
                {/*  Refer to our comprehensive Cookie Policy for
                detailed insights. */}
              </Typography>

              <Typography variant="h6" gutterBottom>
                Social Media Integration
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                When you log in using social network credentials, we receive:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Public profile information, Connected network details and
                Authorized account permissions
              </Typography>

              <Typography variant="h6" gutterBottom>
                Third-Party Sources
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                We supplement our data collection by obtaining information from:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Affiliated organizations, Business partners, External data
                providers, Referral platforms
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                For instance, if you access our site through a partner's
                referral link, we may receive shared information from that
                partner.
              </Typography>
            </Box>
            <Typography variant="body1" gutterBottom sx={{ marginTop: 2 }}>
              Our data collection practices prioritize transparency, user
              consent, and data protection principles.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              3. How We Use Information
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Provide and improve our products and services:
            </Typography>
            <Typography variant="body2" gutterBottom>
              We utilize the information you provide when setting up your
              account to offer our services. This may consist of your basic
              details such as name and email, along with any other information
              you decide to share. With your permission, we might showcase your
              first name, last initial,username, images and profile content
              publicly.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Reviews:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Your reviews and other submitted information may be rephrased or
              edited as needed to enhance clarity and improve readability. When
              submitting reviews, you have to post them with your profile
              displayed. Details such as your name, business name, project
              information, employment details and photo will be visible to
              others alongside your review.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Manage:
            </Typography>
            <Typography variant="body2" gutterBottom>
              We use the information to process transactions and manage your
              account.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Communication:
            </Typography>
            <Typography variant="body2" gutterBottom>
              We use the information to personalize your experience and show
              relevant content/ads. To communicate with you about our products,
              services, and promotions.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Personalize your experience:
            </Typography>
            <Typography variant="body2" gutterBottom>
              We use the information to analyze usage patterns and optimize our
              website/app performance.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Enforce our Terms of Service:
            </Typography>
            <Typography variant="body2" gutterBottom>
              We use the information to comply with legal obligations and
              enforce our terms of service. The information is also used to
              detect and prevent fraud or other malicious activities.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              4. How we share information
            </Typography>
            <Typography variant="body2" gutterBottom>
              Your user profile information, including any content you share on
              Qrade, will be visible to other users. If you post a review, your
              name
              {/* (unless you choose to remain anonymous)  */}
              and the content of your review may also appear in search engine
              results.
            </Typography>

            <Typography variant="body2" gutterBottom>
              Qrade may feature the reviews or testimonials you submit regarding
              us or the service providers we evaluate. This may include your
              name, job title, company affiliation, and any other details you
              provide.
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Service providers:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Payment processors, cloud storage providers, customer support
              tools
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Analytics and advertising partners:
            </Typography>
            <Typography variant="body2" gutterBottom>
              To measure performance and deliver targeted advertising
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Business partners:
            </Typography>
            <Typography variant="body2" gutterBottom>
              For joint marketing efforts or product collaborations
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Legal requirements:
            </Typography>
            <Typography variant="body2" gutterBottom>
              In response to valid legal requests from law enforcement or
              regulatory bodies
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Corporate transactions:
            </Typography>
            <Typography variant="body2" gutterBottom>
              In the event of a merger, acquisition, reorganization, bankruptcy,
              dissolution, liquidation, or sale of assets
            </Typography>

            {/* <Typography variant="body2" paragraph>
              We implement appropriate security measures to protect your
              personal data and only share information as outlined in this
              policy.
            </Typography> */}
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              5. Your Data Privacy Rights
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              California Privacy Rights:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Qrade is committed to protecting your personal data and will not
              share it with third parties without your explicit consent. If you
              would like to modify your preferences or have any questions
              regarding this policy, please reach out to us using the contact
              information provided below.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              European Privacy Rights:
            </Typography>
            <Typography variant="body2" gutterBottom>
              As a user of our services within the European Union, you are
              entitled to the following rights under the General Data Protection
              Regulation (GDPR): right to be informed, right of access, right to
              rectification, right to erasure, right to restrict processing,
              right to data portability, right to object, rights related to
              automated decision-making and profiling If you wish to exercise
              any of these rights, please contact our data protection team. We
              will verify your identity before processing any requests related
              to accessing, correcting, or deleting your personal information.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              6. Data Retention
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              We retain your personal data only as long as necessary, or
              until:You delete your data or request its deletion. We receive a
              written request for deletion.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom>
              7. Contact Information
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              If you have questions or requests regarding this Privacy Policy,
              please contact us at: <a href="mailto: qrade9@gmail.com">email</a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Privacy;
