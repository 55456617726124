import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { isLoggedIn, getUserProfileDetails } from "../../redux/userSlice";
import { Outlet } from "react-router-dom";
import { getParamsByName } from "../../utility/common";
import Constants from "../../config/Constants";
import { loginGoogleLinkedIn, previousRoutes } from "../../redux/userSlice";

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isUserLoggedIn = useAppSelector(isLoggedIn);
  const previousPathRoutes = useAppSelector(previousRoutes);

  useEffect(() => {
    //console.log("isUserLoggedIn Auth:", isUserLoggedIn);
    function retrieveToken() {
      if (isUserLoggedIn) {
        //navigate("/");
      } else {
        //navigate("/login");
        const paramData = getParamsByName("token");

        localStorage.setItem(
          Constants.localStorageKeys.accessToken,
          paramData?.toString()
        );
        //const loc = location.pathname;
        //console.log("location.pathname:", loc);
        //const test = window.location.search;
        //console.log("window.location.search:", test);
        //navigate("/");
        if (paramData) {
          let pathEntity = previousPathRoutes[previousPathRoutes.length - 1];
          dispatch(loginGoogleLinkedIn(true));
          dispatch(getUserProfileDetails());
          navigate(pathEntity.path);
        }
      }
    }
    retrieveToken();
  }, []);

  useEffect(() => {
    //console.log("isUserLoggedIn Auth:", isUserLoggedIn);
    function checkAuth() {
      if (isUserLoggedIn) {
        ////navigate(location.pathname);
        //navigate("/dashboard");
      } else {
        //navigate("/login");
      }
    }
    checkAuth();
  }, []);

  return <Outlet />;
};

export default Auth;
