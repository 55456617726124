import axios, { AxiosPromise } from "axios";
import Config from "../../config/Config";
import { GetLoginConfirmInterface } from "../../interfaces/confirm.interfaces";
import { store } from "../store";
import { GetHeaderKeyTokens } from "./apiUtils";

const userAPIService = axios.create();
const basePath = `${Config().envConfigModule.serverAPI}`;

export const initializeClientAPI = (): AxiosPromise => {
  //const data = JSON.stringify({ data1.username, data1.password });

  let data = {
    key: Config().clientID,
  };
  const config = {
    method: "post",
    url: `${basePath}/auth/initializeClient`,
    headers: {
      "Content-Type": "application/json",
      clientKey: Config().clientKey,
    },
    data: data,
  };

  //const config = {};
  return userAPIService(config);
};

export const logoutAPIUser = (data: any): AxiosPromise => {
  const state = store.getState();
  let apiKey = state.user.apiToken;

  const config = {
    method: "post",
    url: `${basePath}/auth/logout`,
    headers: {
      "Content-Type": "application/json",
      apiKey: apiKey,
    },
    //data: data,
  };

  //const config = {};
  return userAPIService(config);
};

export const signIn = (data: any): AxiosPromise => {
  //const data = JSON.stringify({ data1.username, data1.password });

  const config = {
    method: "post",
    url: `${basePath}/auth/login`,
    headers: {
      "Content-Type": "application/json",
      //'clientkey': Config().clientKey,
    },
    data: data,
  };

  //const config = {};
  return userAPIService(config);
};

export const signUp = (data: any): AxiosPromise => {
  //const data = JSON.stringify({ data1.username, data1.password });

  const config = {
    method: "post",
    //url: `${basePath}/user`,
    url: `${basePath}/auth/register`,
    headers: {
      "Content-Type": "application/json",
      //'clientkey': Config().clientKey,
    },
    data: data,
  };

  //const config = {};
  return userAPIService(config);
};

export const confirmEmail = (data: GetLoginConfirmInterface): AxiosPromise => {
  //const data = JSON.stringify({ data1.username, data1.password });

  const config = {
    method: "post",
    //url: `${basePath}/user`,
    url: `${basePath}/auth/confirm`,
    headers: {
      "Content-Type": "application/json",
      //'clientkey': Config().clientKey,
    },
    data: data,
  };

  //const config = {};
  return userAPIService(config);
};

export const getProfileDetails = (): AxiosPromise => {
  let tokenData = GetHeaderKeyTokens(false);
  const config = {
    method: "get",
    url: `${basePath}/user/profile`,
    headers: {
      "Content-Type": "application/json",
      apiKey: tokenData.apiKey,
      Authorization: `Bearer ${tokenData.token}`,
    },
  };
  return userAPIService(config);
};
