import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Constants from "../../../config/Constants";
import { BorderBottom } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/ExitToApp";
import { loginGoogleLinkedIn, isLoggedIn } from "../../../redux/userSlice";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { useNavigate, useOutletContext } from "react-router-dom";

const componentStyles = {
  appBar: { color: "black", backgroundColor: "white" },
  productName: {
    mr: 0,
    display: { xs: "none", md: "flex" },
    fontFamily: "monospace",
    fontSize: "2.3rem",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "black",
    textDecoration: "none",
  },
  selectedMenuButton: {
    my: 2,
    color: "#9c27b0",
    display: "block",
    borderBottom: 3,
    borderColor: "#9c27b0",
    borderRadius: 0,
  },
  menuButton: {
    my: 2,
    color: "black",
    display: "block",
  },
  loginButton: {
    borderRadius: "9px",
    color: "black",
    borderColor: "black",
    width: {
      xs: 113,
      sm: 113,
      md: 124,
      lg: 124,
    },
  },
};
const pages = [
  { name: "All Companies", value: "All" },
  { name: "Prime Vendors", value: "Prime Vendor" },
  { name: "Sub Contractors", value: "Sub Contractor" },
  { name: "Staffing", value: "Staffing" },
  { name: "Implementation Partners", value: "Implementation Partner" },
];
const settings = ["Profile", "Logout"]; //"Account", "Dashboard",

interface OutletContext {
  handleLoginDialogInHomeLayout: (value: boolean) => void;
}

function NavigationBar(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleLoginDialogInHomeLayout } = useOutletContext<OutletContext>();

  const { onValueSelect, selectedValue } = props;
  const [selectedMenu, setSelectedMenu] = React.useState("All");
  const isUserLoggedIn = useAppSelector(isLoggedIn);

  useEffect(() => {
    try {
      setSelectedMenu(selectedValue);
    } catch (e) {}
  }, [selectedValue]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleGoogleLogin = () => {
  //   window.location.href = "http://localhost:3000/auth/google";
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    //handleMenuChange(value);
  };

  const handleMenuChange = (value: any) => {
    setSelectedMenu(value);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleListCompanyClick = () => {
    if (isUserLoggedIn) {
      // dispatch(
      //   setPreviousRoutes({ path: "/review/add", type: "companynewreview" })
      // );

      navigate(`/company/create`);
    } else {
      handleLoginDialogInHomeLayout(true);
    }
  };

  return (
    <Container maxWidth={false} sx={{ padding: 0 }}>
      <Toolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="default"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ color: "black", display: { xs: "block", md: "none" } }}
          >
            {pages.map((page) => (
              <MenuItem
                sx={{ color: "black" }}
                key={page.value}
                selected={page.value === selectedMenu ? true : false}
                onClick={() => {
                  onValueSelect(page.value);
                  handleCloseNavMenu();
                  handleMenuChange(page.value);
                }}
                //onClick={() => handleCloseNavMenu(page)}
              >
                <Typography sx={{ color: "black", textAlign: "center" }}>
                  {page.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              key={page.value}
              onClick={() => {
                onValueSelect(page.value);
                handleCloseNavMenu();
                handleMenuChange(page.value);
              }}
              //onClick={() => handleCloseNavMenu(page)}
              sx={
                page.value === selectedMenu
                  ? componentStyles.selectedMenuButton
                  : componentStyles.menuButton
              }
            >
              {page.name}
            </Button>
          ))}
        </Box>

        <Button
          sx={{
            paddingLeft: "5px important",
            paddingRight: "5px important",
            paddingTop: "5px important",
            paddingBottom: "5px important",
            border: "1px solid #1976d2",
            borderRadius: "30px",
          }}
          //key={page.value}
          //onClick={() => {
          //  onValueSelect(page.value);
          //  handleCloseNavMenu();
          //  handleMenuChange(page.value);
          //}}
          onClick={() => handleListCompanyClick()}
          //sx={
          //  page.value === selectedMenu
          //    ? componentStyles.selectedMenuButton
          //   : componentStyles.menuButton
          //}
        >
          {"List a Company"}
        </Button>
      </Toolbar>
    </Container>
  );
}
export default NavigationBar;
