import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
  Rating,
  Box,
  Grid,
} from "@mui/material";

// CR.id, CR.companyreview_uuid, CR.userid, CR.companyid,
// CR.ratetype, CR.rate, CR.comments,
// CR.created_at as createdAt, CR.updated_at as updatedAt,
// CR.lastupdate_by as lastUpdateBy,
// USR.user_uuid as userUuid, USR.username,
// USR.profile_imagelink as profileUrl,
// USR.first_name as firstName, USR.last_name as lastName,
// USR.email

// title={`${
//   companyReviewData.lastname ? companyReviewData.lastname : ""
// } ${companyReviewData.firstname}`}
// subheader={
//   <Rating value={companyReviewData.rate} readOnly precision={0.5} />
// }

const CompanyReviewCard = (props: any) => {
  let { companyReviewData } = props;
  return (
    <Card
      sx={{
        maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
        overflow: "auto",
        minHeight: {
          xs: "470px",
          sm: "450px",
          md: "400px",
          lg: "320px",
          xl: "340px",
        },
        paddingTop: 0,
        boxShadow: "1px 4px 8px 1px rgba(0,0,0,0.2)",
        //border: { xs: "none", md: "1px solid #ccc" },
        //m: 2,
        marginBottom: "8px",
      }}
    >
      <CardHeader
        sx={{
          paddingBottom: 1, // Reduce bottom padding of CardHeader
          paddingTop: "10px !important",
          borderBottom: { xs: "1px solid #ccc", md: "1px solid #ccc" },
        }}
        avatar={
          <Avatar
            alt={companyReviewData.username}
            src={companyReviewData.profileurl}
            aria-label={companyReviewData.username}
            sx={{ width: 40, height: 40 }}
          ></Avatar>
        }
        action={<></>}
        title={
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">
                {companyReviewData.username}
              </Typography>
            </Grid>
          </Grid>
        }
        subheader={
          <Grid container alignItems="center" sx={{ mt: { xs: 1, sm: 0 } }}>
            <Grid item xs={12}>
              <Rating value={companyReviewData.rate} readOnly precision={0.5} />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          spacing={{ xs: 2, sm: 3, md: 4 }}
          sx={{
            paddingTop: 0,
            paddingLeft: 0,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              paddingLeft: 0,
            }}
          >
            <Typography variant="h6">{companyReviewData.title}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: "10px !important",
              paddingBottom: "10px !important",
            }}
          >
            <Typography variant="body2">
              {companyReviewData.comments}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12} // Full width on extra small
            sm={12} // Full width on small
            md={6} // 70% width on medium and larger
            lg={6} // 70% width on large
            xl={6} // 70% width on extra large
            sx={{
              paddingTop: "5px !important",
              paddingBottom: "15px !important",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Details:
            </Typography>
            <Typography variant="body2">
              Location: {companyReviewData.location}
            </Typography>
            <Typography variant="body2">
              Pros: {companyReviewData.pros}
            </Typography>
            <Typography variant="body2">
              Cons: {companyReviewData.cons}
            </Typography>
            <Typography variant="body2">
              Summary: {companyReviewData.summary}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12} // Full width on extra small
            sm={12} // Full width on small
            md={6} // 70% width on medium and larger
            lg={6} // 70% width on large
            xl={6} // 70% width on extra large
            sx={{
              borderLeft: { xs: "none", md: "1px solid #ccc" }, // No border on small screens
              //borderTop: { xs: "1px solid #ccc", md: "none" },
              paddingTop: "5px !important",
              "&::before": {
                content: '""',
                display: { xs: "block", sm: "block", md: "none" },
                width: "100%",
                marginLeft: "15px",
                height: "1px",
                backgroundColor: "#ccc",
                position: "absolute",
                top: 0,
                left: 0,
              },
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Reviewer:
            </Typography>
            <Typography variant="body2">
              Employment Type: {companyReviewData.employmenttype}
            </Typography>
            <Typography variant="body2">
              Start Year: {companyReviewData.startyear}
            </Typography>
            <Typography variant="body2">
              End Year: {companyReviewData.endyear}
            </Typography>
            <Typography variant="body2">
              Job Title: {companyReviewData.employmenttitle}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {/* <CardContent
        sx={{
          paddingTop: 1, 
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {companyReviewData.comments}
        </Typography>
      </CardContent> */}
    </Card>
  );
};

export default CompanyReviewCard;
