import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Constants from "../../../config/Constants";
import { BorderBottom } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/ExitToApp";
import Divider from "@mui/material/Divider";
import {
  loginGoogleLinkedIn,
  isLoggedIn,
  logoutUser,
  logout,
  setPreviousRoutes,
  clearPreviousRoutes,
  userProfile,
  //setCurrentParams,
  //clearCurrentParams,
  //previousRoutes,
  //currentParams,
} from "../../../redux/userSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import LoginDialog from "../../../components/dialogs/LoginDialog";
import { trimStringLength } from "../../../utility/common";

const componentStyles = {
  appBar: { color: "black", backgroundColor: "white" },
  productName: {
    mr: 0,
    display: { xs: "none", md: "flex" },
    //fontFamily: "monospace",
    fontSize: "2.3rem",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "black",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      color: "primary.main",
    },
  },
  selectedMenuButton: {
    my: 2,
    color: "#9c27b0",
    display: "block",
    borderBottom: 3,
    borderColor: "#9c27b0",
    borderRadius: 0,
  },
  menuButton: {
    my: 2,
    color: "black",
    display: "block",
  },
  loginButton: {
    borderRadius: "9px",
    color: "black",
    borderColor: "black",
    width: {
      xs: 113,
      sm: 113,
      md: 124,
      lg: 124,
    },
  },
};
const pages = [
  { name: "All Companies", value: "All" },
  { name: "Prime Vendors", value: "Prime Vendor" },
  { name: "Sub Contractors", value: "Sub Contractor" },
  { name: "Staffing", value: "Staffing" },
  { name: "Implementation Partners", value: "Implementation Partner" },
];
const settings = ["Logout"]; //"Account", "Profile", "Dashboard",

function TopAppBar(props: any) {
  const { onValueSelect, selectedValue, handleLoginDialogInHomeLayout } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedMenu, setSelectedMenu] = React.useState("All");
  const [openDialog, setOpenDialog] = React.useState(false);
  const isUserLogged = useAppSelector(isLoggedIn);
  const userProfileDetails = useAppSelector(userProfile);

  useEffect(() => {
    try {
      setSelectedMenu(selectedValue);
    } catch (e) {}
  }, [selectedValue]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleGoogleLogin = () => {
  //   window.location.href = "http://localhost:3000/auth/google";
  // };

  const handleLoginDialogBtn = (value: boolean) => {
    handleLoginDialogInHomeLayout(value);
  };

  // const handleLoginDialog = (value: boolean) => {
  //   setOpenDialog(value);
  // };

  // const onDialogClose = (value: boolean) => {
  //   setOpenDialog(value);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    //handleMenuChange(value);
  };

  const handleMenuChange = (value: any) => {
    setSelectedMenu(value);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    // if (value === "logout") {
    //   dispatch(logoutUser(""));
    // }
  };

  const handleCloseUserItemMenu = (value: any) => {
    setAnchorElUser(null);
    if (value === "Logout") {
      dispatch(logoutUser(""));
      dispatch(logout());
    }
  };

  const handleHomeClick = () => {
    dispatch(clearPreviousRoutes("all"));
    dispatch(setPreviousRoutes({ path: "/", type: "landing" }));
    navigate(`/`);
  };
  //console.log("isUserLogged TopAppBar", isUserLogged);

  return (
    <AppBar position="fixed" sx={componentStyles.appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}

          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={handleHomeClick}
            //href="#app-bar-with-responsive-menu"
            sx={componentStyles.productName}
          >
            {/* Desktop View */}
            {Constants.productName}
            <Typography
              sx={{
                fontSize: "9px",
                letterSpacing: "0",
                color: "#dd2c00",
                textDecoration: "none",
              }}
              variant="caption"
            >
              (beta)
            </Typography>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="default"
            >
              <MenuIcon />
            </IconButton> */}
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ color: "black", display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem
                  sx={{ color: "black" }}
                  key={page.value}
                  selected={page.value === selectedMenu ? true : false}
                  onClick={() => {
                    onValueSelect(page.value);
                    handleCloseNavMenu();
                    handleMenuChange(page.value);
                  }}
                  //onClick={() => handleCloseNavMenu(page)}
                >
                  <Typography sx={{ color: "black", textAlign: "center" }}>
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
          {/* <AdbIcon
            sx={{ color: "black", display: { xs: "flex", md: "none" }, mr: 1 }}
          /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            //href="#app-bar-with-responsive-menu"
            onClick={handleHomeClick}
            sx={{
              mr: 0,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              paddingLeft: 8,
              //fontFamily: "monospace",
              fontSize: "2.3rem",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
              "&:hover": {
                cursor: "pointer",
                color: "primary.main",
              },
            }}
          >
            {/* Mobile View */}
            {Constants.productName}
            <Typography
              sx={{
                fontSize: "8px",
                letterSpacing: "0",
                color: "#dd2c00",
                textDecoration: "none",
              }}
              variant="caption"
              //color="#dd2c00"
            >
              (beta)
            </Typography>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* {pages.map((page) => (
              <Button
                key={page.value}
                onClick={() => {
                  onValueSelect(page.value);
                  handleCloseNavMenu();
                  handleMenuChange(page.value);
                }}
                //onClick={() => handleCloseNavMenu(page)}
                sx={
                  page.value === selectedMenu
                    ? componentStyles.selectedMenuButton
                    : componentStyles.menuButton
                }
              >
                {page.name}
              </Button>
            ))} */}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {!isUserLogged ? (
              <Button
                variant="outlined"
                startIcon={<LoginIcon />}
                sx={componentStyles.loginButton}
                onClick={() => handleLoginDialogBtn(true)}
                //onClick={handleGoogleLogin}
              >
                Sign In
              </Button>
            ) : (
              <>
                <Tooltip
                  title={
                    userProfileDetails &&
                    userProfileDetails.username &&
                    userProfileDetails.username.toUpperCase()
                  }
                >
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{ backgroundColor: "#ec407a" }}
                      alt={
                        userProfileDetails &&
                        userProfileDetails.username &&
                        userProfileDetails.username.toUpperCase()
                      }
                      src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem sx={{ color: "#ec407a" }} key={"userdetails1"}>
                    {"Welcome,"}
                    &nbsp;
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#ec407a",
                      }}
                    >
                      {`${
                        userProfileDetails &&
                        userProfileDetails.username &&
                        trimStringLength(
                          userProfileDetails.username,
                          15
                        ).toUpperCase()
                      }`}
                    </Typography>
                  </MenuItem>
                  <Divider />
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => handleCloseUserItemMenu(setting)}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#ec407a",
                        }}
                      >
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
      {/* {openDialog && (
        <LoginDialog openDialog={openDialog} onDialogClose={onDialogClose} />
      )} */}
    </AppBar>
  );
}
export default TopAppBar;
