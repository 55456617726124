import { store } from "../store";
import Constants from "../../config/Constants";

export const GetHeaderKeyTokens = (onlyApiKey: boolean) => {
  const state = store.getState();
  let apiKey = state.user.apiToken;
  if (onlyApiKey) {
    return {
      apiKey,
    };
  }

  const loginType = localStorage.getItem(Constants.localStorageKeys.loginType);
  const token = localStorage.getItem(Constants.localStorageKeys.accessToken);
  return {
    apiKey,
    token,
  };
};
