import axios, { AxiosPromise, AxiosResponse } from "axios";
import Config from "../../config/Config";
import {
  GetCompanyInterface,
  GetCompanyDetailsInterface,
  CreateCompanyInterface,
} from "../../interfaces/company.interfaces";
import { CreateReviewInterface } from "../../interfaces/review.interfaces";
import { store } from "../store";
import { GetHeaderKeyTokens } from "./apiUtils";
import Constants from "../../config/Constants";
import { logoutUser, logout, setTokenExpired } from "../userSlice";

const coreAPIService = axios.create();
const basePath = `${Config().envConfigModule.serverAPI}`;

export const coreAPIInterceptorOnFulfilled = (
  response: AxiosResponse
): AxiosResponse => {
  return response;
};
export const coreAPIInterceptorOnRejected = async function (error: any) {
  // coreAPIService.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  if (error.response && error.response.status === 401) {
    // Clear user data from local storage or cookies
    //localStorage.removeItem("token");
    localStorage.removeItem(Constants.localStorageKeys.accessToken);
    store.dispatch(logoutUser(""));
    store.dispatch(logout());
    store.dispatch(setTokenExpired("true")); // Since token expired we need to show a message to user.
    // Navigate to home screen
    //window.location.href = "/"; //NOT THIS IS NOT REQD SO DONT UNCOMMENT
  }
  //console.log("error in slice", error);
  return Promise.reject(error);
  //   }
  // );

  //return coreAPIService;
};
coreAPIService.interceptors.response.use(
  coreAPIInterceptorOnFulfilled,
  coreAPIInterceptorOnRejected
);

export const getCompanyList = (data: GetCompanyInterface): AxiosPromise => {
  //const state = store.getState();

  //let apiKey = state.user.apiToken;
  let tokenData = GetHeaderKeyTokens(true);

  let query = `?pageSize=${data.pageSize}&page=${data.page}`;
  if (data.search) {
    query += `&search=${data.search}`;
  }
  if (data.category) {
    query += `&category=${data.category}`;
  }
  if (data.orderByField) {
    query += `&orderByField=${data.orderByField}&sortBy=${data.sortBy}`;
  }

  const config = {
    method: "get",
    url: `${basePath}/company/companies${query}`,
    headers: {
      "Content-Type": "application/json",
      apiKey: tokenData.apiKey, //data.apiKey,
    },
    //data: data,
  };
  return coreAPIService(config);
};

export const getSelectedCompanyDetails = (
  data: GetCompanyDetailsInterface
): AxiosPromise => {
  //const state = store.getState();
  //let apiKey = state.user.apiToken;
  let tokenData = GetHeaderKeyTokens(true);

  let query = `?pageSize=${data.pageSize}&page=${data.page}`;
  if (data.orderByField) {
    query += `&orderByField=${data.orderByField}&sortBy=${data.sortBy}`;
  }

  const config = {
    method: "get",
    url: `${basePath}/company/${data.shortcode}${query}`,
    headers: {
      "Content-Type": "application/json",
      apiKey: tokenData.apiKey,
    },
  };
  return coreAPIService(config);
};

export const saveNewCompany = (data: CreateCompanyInterface): AxiosPromise => {
  //const state = store.getState();
  //let apiKey = state.user.apiToken;
  let tokenData = GetHeaderKeyTokens(false);

  const config = {
    method: "post",
    url: `${basePath}/companystore/company`,
    headers: {
      "Content-Type": "application/json",
      //apiKey: tokenData.apiKey,
      apiKey: tokenData.apiKey,
      Authorization: `Bearer ${tokenData.token}`,
    },
    data: data,
  };
  return coreAPIService(config);
};

export const checkCompanyNameExists = (searchText: string): AxiosPromise => {
  let tokenData = GetHeaderKeyTokens(true);
  const config = {
    method: "get",
    url: `${basePath}/company/companyexists?search=${searchText}`,
    headers: {
      "Content-Type": "application/json",
      apiKey: tokenData.apiKey,
    },
  };
  return coreAPIService(config);
};

export const getLocationsList = (searchText: string): AxiosPromise => {
  let tokenData = GetHeaderKeyTokens(true);
  const config = {
    method: "get",
    url: `${basePath}/common/locations?search=${searchText}`,
    headers: {
      "Content-Type": "application/json",
      apiKey: tokenData.apiKey,
    },
  };
  return coreAPIService(config);
};

export const getUserCompanyReview = (companyid: string): AxiosPromise => {
  let params = `companyId=${companyid}`;
  let tokenData = GetHeaderKeyTokens(false);

  const config = {
    method: "get",
    url: `${basePath}/companystore/company/review?${params}`,
    headers: {
      "Content-Type": "application/json",
      apiKey: tokenData.apiKey,
      Authorization: `Bearer ${tokenData.token}`,
    },
  };
  return coreAPIService(config);
};

export const saveNewReview = (data: CreateReviewInterface): AxiosPromise => {
  //const state = store.getState();
  //let apiKey = state.user.apiToken;
  let tokenData = GetHeaderKeyTokens(false);

  const config = {
    method: "post",
    url: `${basePath}/companystore/review`,
    headers: {
      "Content-Type": "application/json",
      apiKey: tokenData.apiKey,
      Authorization: `Bearer ${tokenData.token}`,
      //token: tokenData.token ? tokenData.token : "",
      //loginType: tokenData.loginType ? tokenData.loginType : "",
    },
    data: data,
    //withCredentials: true,
  };
  return coreAPIService(config);
};
