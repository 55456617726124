import React, { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./HomeLayout.module.css";
import TopAppBar from "./TopAppBar";
import { Outlet } from "react-router-dom";
import { OutletContextType } from "../../../interfaces/company.interfaces";
import { initializeClient, apiInitialized } from "../../../redux/userSlice";
import LoginDialog from "../../../components/dialogs/LoginDialog";
//import Divider from "@mui/material/Divider";
import Footer from "./Footer";

const HomeLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  color: "black",
  //backgroundColor: "#faf9f8",
  backgroundColor: "white",
  paddingTop: 10,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 0,
  },
}));

export const HomeLayout = (props: any) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const isApiInitialized = useAppSelector(apiInitialized);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    try {
      if (!isApiInitialized) {
        dispatch(initializeClient());
      }
    } catch (e) {}
  }, []);

  const handleValueSelect = (value: string) => {
    setSelectedValue(value);
  };

  const contextValue: OutletContextType = {
    selectedValue,
    onValueSelect: handleValueSelect,
  };

  const handleLoginDialogInHomeLayout = (value: boolean) => {
    setOpenDialog(value);
  };

  const onDialogClose = (value: boolean) => {
    setOpenDialog(value);
  };

  // context={{ selectedValue }}

  return (
    <>
      <HomeLayoutRoot>
        <Box className={styles.mainContainer}>
          <Outlet context={{ handleLoginDialogInHomeLayout }} />
        </Box>
        <TopAppBar
          onValueSelect={handleValueSelect}
          selectedValue={selectedValue}
          handleLoginDialogInHomeLayout={handleLoginDialogInHomeLayout}
        />
      </HomeLayoutRoot>
      {/* <Divider /> */}
      <Footer />
      {openDialog && (
        <LoginDialog openDialog={openDialog} onDialogClose={onDialogClose} />
      )}
    </>
  );
};
