import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
  Rating,
  Box,
} from "@mui/material";
import { purple, blueGrey } from "@mui/material/colors";

const componentStyles = {
  companyAvatar: {
    bgcolor: blueGrey[700],
    backgroundSize: "cover",
    backgroundPosition: "top center",
    border: 1,
    "& img": {
      objectFit: "contain", // Ensures the image fits well inside the avatar
    },
  },
};

const CompanyHeaderCard = (props: any) => {
  let { company } = props;
  return (
    <Card sx={{ maxWidth: "100%", paddingTop: 0, mt: 0, mr: 2 }}>
      <CardHeader
        sx={{
          paddingBottom: 0, // Reduce bottom padding of CardHeader
          paddingLeft: "0 important",
        }}
        avatar={
          <Avatar
            sx={componentStyles.companyAvatar}
            variant="rounded"
            key={company.name}
            src={company.logo ? company.logo : ""}
            aria-label={company.name}
          >
            {company.name && company.name.substring(0, 1)}
          </Avatar>
        }
        action={
          <Button
            sx={{
              backgroundColor: purple[700],
              borderColor: "black",
              color: "white",
              marginTop: "10px",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#0062cc",
              },
            }}
            href={company.website}
            target="_blank"
            variant="outlined"
            //color="primary"
            size="small"
          >
            Visit Website
          </Button>
        }
        title={company.name}
        subheader={
          <Rating value={company.averagerate} readOnly precision={0.5} />
        }
      />
      <CardContent
        sx={{
          paddingTop: 1, // Reduce top padding of CardContent
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {company.details}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CompanyHeaderCard;
