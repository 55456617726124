import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  Box,
  Rating,
  Chip,
  Avatar,
  Pagination,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { deepOrange, blueGrey } from "@mui/material/colors";
import styles from "./CompanyDetails.module.css";
//import "./MainContainer.module.css";
import Container from "@mui/material/Container";
import { Height, Margin } from "@mui/icons-material";
import AnchorIcon from "@mui/icons-material/Anchor";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  coreStatus,
  coreMessage,
  companyList,
  companyDetails,
  getCompanyDetails,
  setSelectedCompany,
} from "../../redux/companySlice";
import CustomSearch from "../../components/CustomSearch";
import {
  apiToken,
  apiInitialized,
  setPreviousRoutes,
  clearPreviousRoutes,
  previousRoutes,
} from "../../redux/userSlice";
import { GetCompanyDetailsInterface } from "../../interfaces/company.interfaces";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../interfaces/company.interfaces";
import CompanyCard from "../layout/Home/CompanyCard";
import NavigationBar from "../layout/Home/NavigationBar";
import CompanyHeaderCard from "./CompanyHeaderCard";
import CompanyReviewCard from "./CompanyReviewCard";
import { isLoggedIn } from "../../redux/userSlice";
import CustomBreadCrumbs from "../../components/CustomBreadCrumbs";
import Constants from "../../config/Constants";

const componentStyles = {
  titleCard: {
    padding: 2,
    display: "flex",
    flexDirection: "column",
    marginTop: 9,
    backgroundColor: "var(--primaryColor)",
  },
  titleTypography: {
    paddingTop: 0,
    color: "black",
    textAlign: "center",
    alignContent: "center",
  },
  listContainer: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    minHeight: 150,
    backgroundColor: "white",
  },
  content: {
    //display: "flex",
    //alignItems: "center",
    minWidth: 275,
    minHeight: 380,
    backgroundColor: "white",
    border: 1,
    marginBottom: 1,
  },
  logoIcon: {
    marginLeft: 2,
    marginTop: 2,
    marginBottom: 1,
    //marginRight: 1,
  },
  name: {
    fontWeight: "bold",
    marginLeft: 1,
    marginTop: 2,
    marginBottom: 1,
  },
  website: {
    marginLeft: 1,
    marginTop: 2,
    marginBottom: 1,
  },
  websiteButton: {
    width: 100,
  },
  chipContainer: {
    flexWrap: "wrap",
    maxWidth: 500,
  },
  companyAvatar: {
    bgcolor: blueGrey[700],
    backgroundSize: "cover",
    backgroundPosition: "top center",
    border: 1,
    "& img": {
      objectFit: "contain", // Ensures the image fits well inside the avatar
    },
  },
  companyCardContainer: {
    //display: "flex",
    //alignItems: "center",
    minWidth: 275,
    minHeight: {
      xs: 380,
      sm: 220,
      md: 220,
      lg: 220,
    },
    backgroundColor: "white",
    border: 1,
    marginBottom: 1,
  },
  categoryChip: {
    minWidth: 100,
    //width: 100,
    //margin: "1px",
    marginBottom: 1,
    marginRight: 1,
    backgroundColor: "#f6e2ed", // blueGrey[200],
    fontSize: 11,
    fontWeight: "bold",
    color: "black",
    borderRadius: "5px",
  },
  serviceChip: {
    minWidth: 100,
    width: 100,
    marginBottom: 1,
    marginRight: 1,
    borderRadius: "5px",
    fontSize: 11,
    fontWeight: "bold",
    color: "black",
  },
  search: {
    paddingLeft: "15px",
  },
  noDataContent: {
    alignContent: "center",
    TextAlign: "center",
    alignText: "center",
    minWidth: 275,
    minHeight: 80,
    backgroundColor: "inherit",
    border: 1,
    marginBottom: 1,
  },
};

interface OutletContext {
  handleLoginDialogInHomeLayout: (value: boolean) => void;
}

function CompanyDetails(props: any) {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleLoginDialogInHomeLayout } = useOutletContext<OutletContext>();

  const companyListData = useAppSelector(companyList);
  const companyDetailsData = useAppSelector(companyDetails);
  const isApiInitialized = useAppSelector(apiInitialized);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSizeLimit, setPageSizeLimit] = React.useState(10);
  const [sortByField, setSortByField] = React.useState("rate");
  const [orderBy, setOrderBy] = React.useState("asc");
  const isUserLoggedIn = useAppSelector(isLoggedIn);

  const previousPathRoutes = useAppSelector(previousRoutes);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    try {
      if (id) {
        let code = id ? id : "";
        let data: GetCompanyDetailsInterface = {
          shortcode: code,
          page: currentPage,
          pageSize: pageSizeLimit,
          sortBy: orderBy,
          orderByField: sortByField,
        };

        dispatch(getCompanyDetails(data));
        if (previousPathRoutes.contains.length > 2) {
          dispatch(clearPreviousRoutes(""));
        }
        // dispatch(
        //   setPreviousRoutes({
        //     path: `${location.pathname}`,
        //     type: "companydetails",
        //   })
        // );
      }
    } catch (e) {}
  }, [id]);

  const handleReviewClick = () => {
    if (isUserLoggedIn) {
      // dispatch(
      //   setPreviousRoutes({ path: "/review/add", type: "companynewreview" })
      // );
      dispatch(setSelectedCompany(companyDetailsData[0]));
      navigate(`/review/add`);
    } else {
      handleLoginDialogInHomeLayout(true);
    }
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "0px important",
            paddingLeft: 0,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              marginLeft: "20px",
              marginTop: "70px",
            }}
          >
            <CustomBreadCrumbs
              screenType={Constants.screenType.companyDetails}
              currentLinkText={id}
            />
          </Stack>
          <CompanyHeaderCard
            company={
              companyDetailsData &&
              companyDetailsData.length > 0 &&
              companyDetailsData[0]
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
            sx={{ ml: 2, mr: 2 }}
          >
            <Typography variant="h5" align="left" gutterBottom>
              Reviews
            </Typography>
            <Button
              sx={{
                borderColor: "black",
                color: "black",
                borderRadius: "5px",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="outlined"
              onClick={handleReviewClick}
            >
              Review this company
            </Button>
          </Box>
          {/* <NavigationBar
        //onValueSelect={handleValueSelect}
        //selectedValue={selectedValue}
        /> */}
          <Paper sx={componentStyles.listContainer}>
            {companyDetailsData &&
            companyDetailsData.length > 0 &&
            companyDetailsData[0] &&
            companyDetailsData[0].reviewData.reviews.length > 0 ? (
              companyDetailsData[0].reviewData.reviews.map(
                (companyreview: any) => {
                  return (
                    <CompanyReviewCard
                      key={companyreview.companyreview_uuid}
                      companyReviewData={companyreview}
                    />
                  );
                }
              )
            ) : (
              <Box sx={{ textAlign: "center", backgroundColor: "white" }}>
                {" "}
                <Typography
                  sx={{ backgroundColor: "white" }}
                  variant="subtitle1"
                  color="text.secondary"
                >
                  No Reviews have been submitted for company yet.
                </Typography>
                <Button
                  sx={{
                    color: "#1976d2",
                    "&:hover": {
                      color: "#3f51b5",
                    },
                  }}
                  variant="text"
                  onClick={handleReviewClick}
                >
                  Be the first one to review.
                </Button>{" "}
              </Box>
            )}

            {companyDetailsData &&
              companyDetailsData.length > 0 &&
              companyDetailsData[0] &&
              companyDetailsData[0].reviews &&
              companyDetailsData[0].reviews.length > 0 && (
                <Stack spacing={2}>
                  <Pagination
                    count={
                      companyListData.pagination &&
                      companyListData.pagination.lastPage
                    }
                    // onChange={onPageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CompanyDetails;
