import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "./store";
import {
  initializeClientAPI,
  signIn,
  signUp,
  confirmEmail,
  logoutAPIUser,
  getProfileDetails,
} from "./api/userAPI";

//import { Interface } from "readline";
import { GetLoginInterface } from "../interfaces/login.interfaces";
import {
  GetUserInterface,
  GetInitializeInterface,
} from "../interfaces/user.interfaces";
import { GetLoginConfirmInterface } from "../interfaces/confirm.interfaces";

export interface RoutePathInterface {
  path: string;
  type: string;
}
export interface StateInterface {
  apiStatus: "" | "fulfilled" | "success" | "loading" | "failed" | "api-error";
  loginStatus:
    | ""
    | "fulfilled"
    | "success"
    | "loading"
    | "failed"
    | "login-error";
  registerStatus:
    | ""
    | "fulfilled"
    | "success"
    | "loading"
    | "failed"
    | "register-error";
  confirmStatus:
    | ""
    | "fulfilled"
    | "success"
    | "loading"
    | "failed"
    | "confirm-error";
  tokenExpired: "" | "false" | "true";
  isLoggedIn: boolean;
  apiToken: string;
  apiInitialized: boolean;
  accessToken: string;
  refreshToken: string;
  userProfile: object;
  loginMessage: string;
  registerMessage: string;
  confirmMessage: string;
  emailConfirmed: boolean;
  previousRoutes: RoutePathInterface[];
  currentParams: string;
}

const initialState: StateInterface = {
  apiStatus: "loading",
  loginStatus: "fulfilled",
  registerStatus: "fulfilled",
  confirmStatus: "fulfilled",
  tokenExpired: "",
  isLoggedIn: false,
  apiToken: "",
  apiInitialized: false,
  accessToken: "",
  refreshToken: "",
  userProfile: {},
  loginMessage: "",
  registerMessage: "",
  confirmMessage: "",
  emailConfirmed: false,
  previousRoutes: [],
  currentParams: "",
};

export const initializeClient = createAsyncThunk(
  "user/initializeClient",
  async () => {
    try {
      const response = await initializeClientAPI();

      return response.data;
    } catch (error) {
      console.error("Init Error:", error);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "user/logoutUser",
  async (data: any) => {
    const response = await logoutAPIUser(data);
    return response.data;
  }
);

export const loginUser = createAsyncThunk(
  "user/login",
  async (data: GetLoginInterface) => {
    const response = await signIn(data);
    return response.data;
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (data: GetUserInterface) => {
    const response = await signUp(data);
    return response.data;
  }
);

export const confirmUserEmail = createAsyncThunk(
  "user/confirmEmail",
  async (data: GetLoginConfirmInterface) => {
    const response = await confirmEmail(data);
    return response.data;
  }
);

export const getUserProfileDetails = createAsyncThunk(
  "user/getUserProfileDetails",
  async () => {
    const response = await getProfileDetails();
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    loginGoogleLinkedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.emailConfirmed = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.userProfile = {};
      //state.message = "";
      state.loginMessage = "";
      state.registerMessage = "";
      state.confirmMessage = "";
      //state.status = "";
      state.loginStatus = "";
      state.registerStatus = "";
      state.confirmStatus = "";
    },
    clearLoginStatus: (state) => {
      state.loginStatus = "";
      state.loginMessage = "";
      state.emailConfirmed = false;
    },
    clearRegisterStatus: (state) => {
      state.registerStatus = "";
      state.registerMessage = "";
      state.emailConfirmed = false;
    },
    clearConfirmStatus: (state) => {
      state.confirmStatus = "";
      state.confirmMessage = "";
      state.emailConfirmed = false;
    },
    setPreviousRoutes: (state, action) => {
      if (action.payload) {
        const path = action.payload.path; // example: route path
        const type = action.payload.type; // example: detailspage
        if (path.length > 0) {
          if (path.indexOf("landing") >= 0) {
            state.previousRoutes = [];
          }
          let routeExists = state.previousRoutes.findIndex(
            (item) => item.type === type
          );
          if (routeExists === -1) {
            let newRoute: RoutePathInterface = {
              path: path,
              type: type,
            };
            state.previousRoutes.push(newRoute);
          }
        }
      }
    },
    clearPreviousRoutes: (state, action) => {
      if (action.payload === "all") {
        state.previousRoutes = [];
      } else {
        if (state.previousRoutes.length > 0) {
          state.previousRoutes.pop(); // remove last entered row.
        } else {
          state.previousRoutes = [];
        }
      }
    },
    setCurrentParams: (state, action) => {
      if (action.payload) state.currentParams = action.payload;
    },
    clearCurrentParams: (state, action) => {
      state.currentParams = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeClient.pending, (state) => {
        state.apiStatus = "loading";
      })
      .addCase(initializeClient.fulfilled, (state, action) => {
        state.apiStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.apiStatus = "api-error";
          state.apiInitialized = false;
        } else {
          state.apiToken = action.payload.tokenKey;
          state.apiInitialized = true;
        }
      })
      .addCase(initializeClient.rejected, (state, action) => {
        state.apiStatus = "api-error";
      })
      .addCase(getUserProfileDetails.pending, (state) => {
        state.apiStatus = "loading";
      })
      .addCase(getUserProfileDetails.fulfilled, (state, action) => {
        state.apiStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.apiStatus = "api-error";
        } else {
          state.userProfile =
            action.payload && action.payload.length > 0 && action.payload[0];
        }
      })
      .addCase(getUserProfileDetails.rejected, (state, action) => {
        state.apiStatus = "api-error";
      })
      .addCase(loginUser.pending, (state) => {
        state.loginStatus = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loginStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.loginStatus = "login-error";
          state.loginMessage = action.payload.message || "";
          state.isLoggedIn = false;
        } else {
          state.isLoggedIn = true;
          state.accessToken = action.payload.access_token;
          state.refreshToken = action.payload.refresh_token;
          //state.userProfile = action.payload;
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loginStatus = "login-error";
        state.loginMessage = action.error.message || "";
        state.isLoggedIn = false;
      })
      .addCase(registerUser.pending, (state) => {
        state.registerStatus = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        if (action.payload.status === 400) {
          state.registerStatus = "register-error";
          state.registerMessage = "Email already exists";
        } else {
          state.registerStatus = "success";
        }
        //state.isLoggedIn = true;
        //state.accessToken = action.payload.access_token;
        //state.refreshToken = action.payload.refresh_token;
        //state.userProfile = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registerStatus = "register-error";
        state.registerMessage = action.error.message || "";
        state.isLoggedIn = false;
      })
      .addCase(confirmUserEmail.pending, (state) => {
        state.confirmStatus = "loading";
      })
      .addCase(confirmUserEmail.fulfilled, (state, action) => {
        if (action.payload.status === 400) {
          state.confirmStatus = "confirm-error";
          state.emailConfirmed = false;
          state.confirmMessage = "Email already exists";
        } else {
          state.emailConfirmed = true;
          state.confirmStatus = "success";
        }
      })
      .addCase(confirmUserEmail.rejected, (state, action) => {
        state.confirmStatus = "confirm-error";
        state.confirmMessage = action.error.message || "";
        state.isLoggedIn = false;
        state.emailConfirmed = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loginStatus = "loading";
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loginStatus = "fulfilled";

        // if (action.payload.status === 404) {
        //   state.loginStatus = "login-error";
        //   state.loginMessage = action.payload.message || "";
        //   state.isLoggedIn = false;
        // } else {
        //   state.isLoggedIn = false;
        //   state.accessToken = action.payload.access_token;
        //   state.refreshToken = action.payload.refresh_token;
        //   //state.userProfile = action.payload;
        // }
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loginStatus = "login-error";
        state.loginMessage = action.error.message || "";
        state.isLoggedIn = false;
      });
    // .addCase(loginUser.error, (state) => {
    //   state.status = "error";
    // });
  },
});

//export const userSelector = (state) => state.user
export const {
  logout,
  loginGoogleLinkedIn,
  clearLoginStatus,
  clearRegisterStatus,
  clearConfirmStatus,
  setPreviousRoutes,
  clearPreviousRoutes,
  setCurrentParams,
  clearCurrentParams,
  setTokenExpired,
} = userSlice.actions;

export const apiInitialized = (state: RootState) => state.user.apiInitialized;
export const apiToken = (state: RootState) => state.user.apiToken;

export const previousRoutes = (state: RootState) => state.user.previousRoutes;
export const currentParams = (state: RootState) => state.user.currentParams;

export const isLoggedIn = (state: RootState) => state.user.isLoggedIn;
export const accessToken = (state: RootState) => state.user.accessToken;
export const refreshToken = (state: RootState) => state.user.refreshToken;
export const emailConfirmed = (state: RootState) => state.user.emailConfirmed;
//export const status = (state: RootState) => state.user.status;

export const loginStatus = (state: RootState) => state.user.loginStatus;
export const registerStatus = (state: RootState) => state.user.registerStatus;
export const confirmStatus = (state: RootState) => state.user.confirmStatus;
export const tokenExpired = (state: RootState) => state.user.tokenExpired;

export const loginMessage = (state: RootState) => state.user.loginMessage;
export const registerMessage = (state: RootState) => state.user.registerMessage;
export const confirmMessage = (state: RootState) => state.user.confirmMessage;
export const userProfile = (state: RootState) => state.user.userProfile;
export default userSlice.reducer;
