import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";

const componentStyles = {
  clearButton: {
    margin: 0,
  },
};

export default function CustomizedSearch(props: any) {
  const [searchText, setSearchText] = React.useState("");
  const { onSearchChange } = props;

  const handleKeyDown = (event: any) => {
    if (
      event.key === "Enter" &&
      (searchText.length > 2 || searchText.length === 0)
    ) {
      onSearchChange(event, searchText);
      //setSearchText("");
    }
  };

  const clearClick = (event: any) => {
    onSearchChange(event, "");
    setSearchText("");
  };

  const onButtonClick = (event: any) => {
    if (searchText.length > 2 || searchText.length === 0) {
      onSearchChange(event, searchText);
      //setSearchText("");
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 340,
        border: 1,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search companies"
        inputProps={{
          "aria-label": "search",
        }}
        value={searchText}
        onKeyDown={handleKeyDown}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
        }}
      />
      <IconButton>
        {searchText.length > 0 ? (
          <CancelSharpIcon fontSize="small" onClick={clearClick} />
        ) : (
          ""
        )}
      </IconButton>
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        onClick={onButtonClick}
        aria-label="search"
      >
        <SearchIcon fontSize="medium" />
      </IconButton>
    </Paper>
  );
}
