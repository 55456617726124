import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  Rating,
  Chip,
  Avatar,
  Pagination,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { deepOrange, blueGrey } from "@mui/material/colors";
import styles from "./CreateEditCompany.module.css";
import "./CreateEditCompany.module.css";
import Container from "@mui/material/Container";
import { Height, Margin } from "@mui/icons-material";
import AnchorIcon from "@mui/icons-material/Anchor";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  coreStatus,
  coreMessage,
  companyList,
  getCompanies,
  checkCompany,
  companySaved,
  locationsList,
  getLocations,
  saveCompanyStatus,
  saveCompany,
  clearSaveCompany,
} from "../../redux/companySlice";
import { v4 as uuidv4 } from "uuid";
import CustomSearch from "../../components/CustomSearch";
import { apiToken, apiInitialized } from "../../redux/userSlice";
import { GetCompanyInterface } from "../../interfaces/company.interfaces";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  OutletContextType,
  CreateCompanyInterface,
  CompanyCategoryServices,
} from "../../interfaces/company.interfaces";
import CompanyCard from "../layout/Home/CompanyCard";
import NavigationBar from "../layout/Home/NavigationBar";
import { CompanyErrors, AlertState } from "../../interfaces/company.interfaces";
import CustomBreadCrumbs from "../../components/CustomBreadCrumbs";
import CustomImageSelector from "../../components/CustomImageSelector";
import Constants from "../../config/Constants";
import { purple } from "@mui/material/colors";
import CustomSnackbar from "../../components/CustomSnackBar";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import validator from "validator";
import { validatePhone, ensureURLHasHttps } from "../../utility/common";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const componentStyles = {};

// INSERT INTO public.company(
// 	company_uuid, name, slogan, legalname, previousname,
// 	details,
// 	industry, tags, isverified, verifiedbyname, verifiedbyguid,
// 	verifieddetails, yearfound, avghourlyrate, avgsalaryrange, totalemployees, logo,
// 	about, website, phone, secondaryphone, primarycontactname, email,
// 	rate_average, merged_date, sold_date, status, county, street,
// 	street2, city, state, zip, country,
// 	jobportal_link,
// 	created_at, updated_at, lastupdate_by, delete_status)
// 	VALUES (gen_random_uuid(), 'Apex Systems', NULL, NULL, NULL,
// 			'Apex Systems partners with global and Fortune 500 companies, leveraging cutting-edge technology through strategic alliances to drive businesses forward.',
// 			'IT Consulting', NULL, 'N', NULL, NULL,
// 			NULL, NULL, NULL, NULL, NULL,
// 			'https://qradeteam.nyc3.cdn.digitaloceanspaces.com/companylogos/apex.png',
// 			NULL, 'https://www.apexsystems.com/', '804-254-2600', NULL, NULL, NULL,
// 			NULL, NULL, NULL, 'Active', NULL, '4400 Cox Road Suite 200',
// 			NULL, 'Glen Allen', 'VA', '23060', 'US',
// 			'https://www.apexsystems.com/search-results-usa',
// 			CURRENT_TIMESTAMP, CURRENT_TIMESTAMP, 'ADMIN', 'N');

// INSERT INTO public.companycategory(
// 	companycategory_uuid, companyid, type, name, code, description,
// 	created_at, updated_at, lastupdate_by, delete_status)
// 	VALUES (gen_random_uuid(), 2, 'consulting', 'Prime Vendor', 'PrimeVendor', NULL,
// 			CURRENT_TIMESTAMP, CURRENT_TIMESTAMP, 'ADMIN', 'N');

// INSERT INTO public.companyservices(
// 	companyservice_uuid, companyid, type, name, code, description,
// 	created_at, updated_at, lastupdate_by, delete_status)
// 	VALUES (gen_random_uuid(), 8, 'Legal', 'H1B', 'H1B', NULL,
// 			CURRENT_TIMESTAMP, CURRENT_TIMESTAMP, 'ADMIN', 'N');
const categoryItems = [
  {
    name: "Staffing",
    label: "Staffing",
    code: "staffing",
    rateType: "consulting",
    disable: false,
  },
  {
    name: "Sub Contractor",
    label: "Sub Contractor",
    code: "subcontractor",
    rateType: "consulting",
    disable: false,
  },
  {
    name: "Prime Vendor",
    label: "Prime Vendor",
    code: "primevendor",
    rateType: "consulting",
    disable: false,
  },
  {
    name: "Implementation Partner",
    label: "Implementation Partner",
    code: "implementationpartner",
    rateType: "consulting",
    disable: false,
  },
];

const servicesItems = [
  { name: "W2", label: "W2", code: "w2", rateType: "payment" },
  {
    name: "1099",
    label: "1099",
    code: "1099",
    rateType: "payment",
  },
  {
    name: "Corp to corp",
    label: "Corp to corp",
    code: "ctoc",
    rateType: "legal",
  },
  { name: "H1B", label: "H1B", code: "h1b", rateType: "legal" },
  {
    name: "Green Card",
    label: "Green Card",
    code: "greencard",
    rateType: "legal",
  },
];

function CreateEditCompany(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyData = useAppSelector(companyList);
  const locationData = useAppSelector(locationsList);
  const isApiInitialized = useAppSelector(apiInitialized);
  const apiTokenKey = useAppSelector(apiToken);
  const saveCompStatus = useAppSelector(saveCompanyStatus);
  const coreSaveMessage = useAppSelector(coreMessage);

  const defaultFormData = {
    uuid: "",
    name: "",
    details: "",
    industry: "",
    isverified: "N",
    logo: "",
    website: "",
    jobsite: "",
    phone: "",
    email: "",
    status: "",
    location: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    enteredByOwner: false,
    category: [
      {
        neweditdel: "", // new, edit, delete
        uuid: "",
        compid: -1,
        type: "",
        name: "",
        code: "",
        description: "",
      },
    ],
    services: [
      {
        neweditdel: "",
        uuid: "",
        compid: -1,
        type: "",
        name: "",
        code: "",
        description: "",
      },
    ],
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [snackBarState, setSnackBarState] = React.useState<AlertState>({
    open: false,
    message: "Info",
    severity: "success",
  });

  const { selectedValue, onValueSelect } =
    useOutletContext<OutletContextType>();

  const [searchText, setSearchText] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState(selectedValue);

  const [errors, setErrors] = useState<CompanyErrors>({
    name: "",
    details: "",
    logo: "",
    website: "",
    location: "",
    jobsite: "",
    phone: "",
    email: "",
    category: "",
    services: "",
  });
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //console.log("locationData", locationData);
    if (locationData) {
      setOptions(
        Array.isArray(locationData.locationdata)
          ? locationData.locationdata
          : []
      );
    }
  }, [locationData]);

  useEffect(() => {
    if (saveCompStatus.status === "fulfilled") {
      setSnackBarState({
        ...snackBarState,
        open: true,
        message: "Saved Company. Thank you",
        severity: "success",
      });
      //dispatch(clearPreviousRoutes(""));
      //let pathEntity = previousPathRoutes[previousPathRoutes.length - 1];

      navigate("/");
    } else if (saveCompStatus.status === "error") {
      //console.log("saveCompStatus", saveCompStatus);

      setSnackBarState({
        ...snackBarState,
        open: true,
        message:
          saveCompStatus.validationCode === 400
            ? saveCompStatus.message[0]
            : saveCompStatus.message,
        severity: "error",
      });
      // company already exists
      if (saveCompStatus.apiCode === 409) {
        setErrors({ ...errors, name: saveCompStatus.message });
      }
      dispatch(clearSaveCompany());
    }
  }, [saveCompStatus]);

  useEffect(() => {
    return () => {
      // This cleanup function will run when the component unmounts
      // or when the route changes
      dispatch(clearSaveCompany());
    };
  }, []);

  // const onSearchChange = (event: any, value: any) => {
  //   onValueSelect("");
  //   setSearchText("");
  //   let params: GetCompanyInterface = {
  //     apiKey: apiTokenKey,
  //     page: currentPage,
  //     pageSize: pageSizeLimit,
  //     sortBy: sortByField,
  //     orderByField: orderBy,
  //     search: value,
  //     category: "",
  //   };
  //   dispatch(getCompanies(params));
  //   event.preventDefault();
  // };

  // useEffect(() => {
  //   dispatch(getUserCompReviewDetails(selectedCompanyData.uuid));
  // }, []);

  // checkCompany,
  // companySaved,
  // locationsList,

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    //setOpenSnackBar(false);
    setSnackBarState({ ...snackBarState, open: false });
  };

  const validateForm = () => {
    const newErrors: CompanyErrors = {
      name: "",
      details: "",
      logo: "",
      website: "",
      location: "",
      jobsite: "",
      phone: "",
      email: "",
      category: "",
      services: "",
    };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (formData.name.trim().length < 3) {
      newErrors.name = "Minimum 3 characters are required";
    }
    if (!formData.details.trim()) {
      newErrors.details = "Company details are required";
    }
    if (formData.details.trim().length < 50) {
      newErrors.details = "Minimum 50 chars are required";
    }

    if (!formData.website.trim()) {
      newErrors.website = "Website URL is required";
    }
    if (!validator.isURL(formData.website.trim())) {
      newErrors.website = "Please enter a valid website URL";
    }

    if (!formData.location.trim()) {
      newErrors.location = "Location is required";
    }

    if (formData.enteredByOwner === true) {
      // if (!formData.logo) {
      //   newErrors.logo = "Company logo is required";
      // }
      if (!formData.phone) {
        newErrors.phone = "Phone is required";
      }
      // if (!validator.isMobilePhone(formData.phone.trim())) {
      //   newErrors.phone = "Invalid Phone number";
      // }

      if (!validatePhone(formData.phone.trim())) {
        newErrors.phone = "Please enter a valid phone number 0000000000";
      }

      if (!formData.email) {
        newErrors.email = "Email is required";
      }
      if (!validator.isEmail(formData.email.trim())) {
        newErrors.email = "Please enter a valid email";
      }
      if (formData.jobsite.trim().length > 0) {
        if (!validator.isURL(formData.jobsite.trim())) {
          newErrors.jobsite = "Please enter a valid website URL";
        }
      }
    }

    if (formData.category.length <= 1) {
      newErrors.category = "Category is required. Please select atleast one.";
    }
    if (formData.services.length <= 1) {
      newErrors.services = "Services is required. Please select atleast one.";
    }

    return newErrors;
  };

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target;
    //console.log("value", name);
    if (name === "enteredByOwner") {
      setFormData({ ...formData, [name]: checked });
    } else if (name === "phone") {
      if (/^\d*$/.test(value) && value.length <= 10) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setErrors({ ...errors, [name]: "" });
  };

  const handleChangeCheck = (event: any, type: string, data: any) => {
    const { name, value, checked } = event.target;

    let newItem = {
      neweditdel: "new",
      uuid: "",
      compid: -1,
      type: data.rateType,
      name: data.name,
      code: data.code,
      description: data.name,
    };

    // prevState.classes.filter(classItem => classItem.id !== idToRemove)
    if (checked === true) {
      if (type === "category") {
        setFormData({ ...formData, [type]: [...formData.category, newItem] });
      } else if (type === "services") {
        setFormData({ ...formData, [type]: [...formData.services, newItem] });
      }
    } else if (checked === false) {
      if (type === "category") {
        setFormData({
          ...formData,
          [type]: formData.category.filter(
            (item) => item.code !== data.rateType
          ),
        });
      } else if (type === "services") {
        setFormData({
          ...formData,
          [type]: formData.services.filter(
            (item) => item.code !== data.rateType
          ),
        });
      }
    }

    // if (name === "enteredByOwner") {
    //   setFormData({ ...formData, [name]: checked });
    // } else {
    //   setFormData({ ...formData, [name]: value });
    // }

    //console.log("FormData", formData);
    setErrors({ ...errors, [type]: "" });
  };

  const handleLocationsChange = (locationText: any) => {
    if (locationText.length >= 3) {
      setFormData({ ...formData, location: locationText });
      dispatch(getLocations(locationText));
    }
    if (locationText === "") {
      setFormData({ ...formData, location: locationText });
    } else {
      setErrors({ ...errors, location: "" });
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newErrors = validateForm();

    //console.log("newErrors", newErrors);
    const checkErrors = Object.values(newErrors).some(
      (value) => value !== null && value !== ""
    );

    if (!checkErrors) {
      let uuid = formData.uuid ? formData.uuid : uuidv4();
      let categoryArray: CompanyCategoryServices[] = [];
      formData.category.map((item: any) => {
        if (item.name) {
          let data: CompanyCategoryServices = {
            neweditdel: item.neweditdel,
            uuid: item.uuid,
            compid: item.compid,
            type: item.type,
            name: item.name,
            code: item.code,
            description: item.description,
          };
          categoryArray.push(data);
        }
      });
      let servicesArray: CompanyCategoryServices[] = [];
      formData.services.map((item: any) => {
        if (item.name) {
          let data: CompanyCategoryServices = {
            neweditdel: item.neweditdel,
            uuid: item.uuid,
            compid: item.compid,
            type: item.type,
            name: item.name,
            code: item.code,
            description: item.description,
          };
          servicesArray.push(data);
        }
      });

      let saveData: CreateCompanyInterface = {
        editExistingCompany: formData.uuid ? true : false,
        companyUuid: uuid,
        //userid: "", // logged in userid
        name: formData.name,
        details: formData.details,
        type: "", //formData
        industry: formData.industry,
        isverified: "N",
        companylogo: formData.logo,
        website: ensureURLHasHttps(formData.website),
        enteredByOwner: formData.enteredByOwner ? true : false,
        phonenumber: formData.phone,
        emailaddress: formData.email,
        job_website: ensureURLHasHttps(formData.jobsite),
        ownership: 0,
        purchase_date: "",
        sold_date: "",
        status: "Active",
        location: formData.location,
        county: "",
        street: "",
        street2: "",
        state: "",
        zipcode: "",
        country: "",
        category: categoryArray,
        services: servicesArray,
      };
      //console.log("saveData", saveData);
      dispatch(saveCompany(saveData));
      // Handle form submission here
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Card
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            marginTop: 9,
            border: 0,
            backgroundColor: "var(--primaryColor)",
          }}
        >
          <Stack
            sx={
              {
                //display: "flex",
                //marginLeft: "20px",
                //marginTop: "70px",
              }
            }
          >
            <CustomBreadCrumbs
              screenType={Constants.screenType.companyEdit}
              //companyCode={"list company"}
              currentLinkText={"company"}
            />
          </Stack>
          <Typography
            sx={{
              paddingTop: 0,
              color: "black",
              textAlign: "center",
              alignContent: "center",
            }}
            variant="h4"
            component="div"
          >
            List a Consulting Company
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {/* <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 940,
            //backgroundColor: "#faf9f8",
          }}
        > */}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ maxWidth: 800, margin: "auto", p: 0 }}
        >
          <CustomSnackbar
            open={snackBarState.open}
            handleClose={handleSnackBarClose}
            message={snackBarState.message}
            severity={snackBarState.severity}
          />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="legend" sx={{ fontWeight: "bold" }}>
                Name *
              </Typography>
              <TextField
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                //inputProps={{ minLength: 3 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend" sx={{ fontWeight: "bold" }}>
                About *
              </Typography>
              <TextField
                fullWidth
                key={"about"}
                //label="Comments"
                name="details"
                multiline
                rows={3}
                value={formData.details}
                onChange={handleChange}
                error={!!errors.details}
                helperText={errors.details}
                inputProps={{ minLength: 3 }}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography component="legend" sx={{ fontWeight: "bold" }}>
                Categories *
              </Typography>
              {!!errors.category && (
                <Typography
                  component="body"
                  sx={{
                    color: "red",
                    marginLeft: "14px",
                    fontSize: "12px",
                    display: "block",
                  }}
                >
                  {errors.category}
                </Typography>
              )}

              {categoryItems.map((item) => (
                <FormControlLabel
                  key={item.name}
                  sx={{
                    marginTop: "7px",
                    marginLeft: "1px",
                    marginBottom: "5px",
                    border: "1px solid #1976d2",
                    borderRadius: "30px",
                    padding: "2px 4px",
                    //backgroundColor: "black",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                      //color: "#1976d2",
                      color: "black",
                    },
                    "& .MuiCheckbox-root": {
                      padding: "1px",
                      //backgroundColor: "white",
                    },
                    "&:hover": {
                      //color: "black",
                      backgroundColor: "rgba(25, 118, 210, 0.04)",
                    },
                  }}
                  control={
                    <Checkbox
                      key={item.name}
                      sx={{
                        color: purple[800],
                        "&.Mui-checked": {
                          color: "black",
                        },
                        "&.Mui-root": {
                          color: "red",
                        },
                        "& .MuiSvgIcon-root": {
                          //backgroundColor: "white",
                          fontSize: 27,
                        },
                      }}
                      //checked={gilad}
                      onChange={(event) =>
                        handleChangeCheck(event, "category", item)
                      }
                      //error={!!errors.details}
                      //helperText={errors.details}
                      name={item.name}
                    />
                  }
                  label={item.label}
                />
              ))}
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend" sx={{ fontWeight: "bold" }}>
                Services *
              </Typography>
              {!!errors.services && (
                <Typography
                  component="body"
                  sx={{
                    color: "red",
                    marginLeft: "14px",
                    fontSize: "12px",
                    display: "block",
                  }}
                >
                  {errors.services}
                </Typography>
              )}
              {servicesItems.map((item) => (
                <FormControlLabel
                  key={item.name}
                  control={
                    <Checkbox
                      key={item.name}
                      sx={{
                        color: purple[800],
                        //color: "#607d8b",
                        "&.Mui-checked": {
                          color: "black",
                        },
                        "& .MuiSvgIcon-root": { fontSize: 27 },
                      }}
                      //checked={formData.enteredByOwner}
                      onChange={(event) =>
                        handleChangeCheck(event, "services", item)
                      }
                      name={item.name}
                    />
                  }
                  label={item.label}
                />
              ))}
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend" sx={{ fontWeight: "bold" }}>
                Website *
              </Typography>
              <TextField
                fullWidth
                //label="website"
                name="website"
                value={formData.website}
                onChange={handleChange}
                error={!!errors.website}
                helperText={errors.website}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="legend"
                sx={{ fontWeight: "bold", paddingBottom: "10px" }}
              >
                Location *
              </Typography>
              <Autocomplete
                options={options}
                loading={loading}
                //onSelect={handleChange}
                filterOptions={(x) => x} // Disable built-in filtering to rely on API
                onInputChange={(event, newInputValue) =>
                  handleLocationsChange(newInputValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search by city name"
                    name="location"
                    value={formData.location}
                    error={!!errors.location}
                    helperText={errors.location}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <span>Loading...</span> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              {/* <TextField
                fullWidth
                name="location"
                value={formData.location}
                onChange={handleChange}
                error={!!errors.location}
                helperText={errors.location}
              /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <Typography component="legend" sx={{ fontWeight: "bold" }}>
                Name *
              </Typography> */}
              <FormControlLabel
                sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
                control={
                  <Checkbox
                    sx={{
                      color: purple[800],
                      "&.Mui-checked": {
                        //color: purple[600],
                        color: "black",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 30 },
                    }}
                    checked={formData.enteredByOwner}
                    onChange={handleChange}
                    name="enteredByOwner"
                  />
                }
                label="Are you the owner or an administrator of this company?"
              />
            </Grid>
            {formData.enteredByOwner === true && (
              <>
                {/* <Grid item xs={12}>
                  <Typography component="legend" sx={{ fontWeight: "bold" }}>
                    Company Logo Image *
                  </Typography>
                  <Typography variant="body2">
                    (Only png or jpeg file types are allowed)
                  </Typography>
                  {!!errors.logo && (
                    <Typography
                      component="body"
                      sx={{
                        color: "red",
                        marginLeft: "14px",
                        fontSize: "12px",
                        display: "block",
                      }}
                    >
                      {errors.logo}
                    </Typography>
                  )}
                  <CustomImageSelector />
                </Grid> */}
                <Grid item xs={6}>
                  <Typography component="legend" sx={{ fontWeight: "bold" }}>
                    Company Email *
                  </Typography>
                  <TextField
                    fullWidth
                    //label="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography component="legend" sx={{ fontWeight: "bold" }}>
                    Company Phone *
                  </Typography>
                  <TextField
                    fullWidth
                    //type="number"
                    // inputProps={{
                    //   minLength: 10,
                    //   maxLength: 10,
                    //   //inputMode: "tel",
                    // }}
                    //placeholder="(___) ___-____"
                    placeholder="0000000000"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography component="legend" sx={{ fontWeight: "bold" }}>
                    Company Careers Page
                  </Typography>
                  <TextField
                    fullWidth
                    //label="company careers page"
                    name="jobsite"
                    value={formData.jobsite}
                    onChange={handleChange}
                    error={!!errors.jobsite}
                    helperText={errors.jobsite}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Button
                sx={{ backgroundColor: "black" }}
                type="submit"
                variant="contained"
                fullWidth
              >
                Save Company
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
}

export default CreateEditCompany;
