import Constants from "../config/Constants";

export const validateIsEmail = (email: string) => {
  return Constants.emailRegEx.test(email);
};

export function getParamsByName(name: any) {
  const match: any = RegExp("[?&]" + name + "=([^&]*)").exec(
    window.location.search
  );
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

export const getYearRange = (totalYears: number) => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - totalYears;
  return Array.from(
    { length: totalYears + 1 },
    (_, index) => startYear + index
  );
};

export const validatePhone = (phoneNumber: string) => {
  const phoneRegex = /^[0-9]{10}$/;
  ///^\+?(\d{1,3})?[-.\s]?(\d{3})[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
  return phoneRegex.test(phoneNumber);
};

export function ensureURLHasHttps(url: string) {
  if (!url) return ""; // Handle empty or undefined URLs
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
}

export const trimStringLength = (input: string, maxLength: number) => {
  if (input.length <= maxLength) {
    return input;
  }
  return input.slice(0, maxLength - 0) + "...";
};
