import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Rating,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Stack,
  Autocomplete,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ReviewErrors,
  CreateReviewInterface,
  ReviewsRateInterface,
} from "../../interfaces/review.interfaces";
import {
  selectedCompany,
  clearSelectedCompany,
  saveCompanyReview,
  saveReviewStatus,
  getUserCompReviewDetails,
  userCompanyReview,
  clearSaveReview,
  getLocations,
  locationsList,
} from "../../redux/companySlice";
import { previousRoutes, clearPreviousRoutes } from "../../redux/userSlice";
import { v4 as uuidv4 } from "uuid";
import CustomSnackbar from "../../components/CustomSnackBar";
import { AlertColor } from "@mui/material/Alert";
import { getYearRange } from "../../utility/common";
import CustomBreadCrumbs from "../../components/CustomBreadCrumbs";
import Constants from "../../config/Constants";
import CustomLink from "../../components/CustomLink";

const ratingItems = [
  { name: "hourly rates", label: "Hourly Rates", rateType: "hourlyrates" },
  { name: "management", label: "Management", rateType: "management" },
  {
    name: "payments on time",
    label: "Payments on time",
    rateType: "paymentsontime",
  },
  { name: "onboarding", label: "Onboarding", rateType: "onboarding" },
];

const CompanyNewReview = () => {
  const defaultFormData = {
    uuid: "",
    rate: 0,
    ratings: [
      {
        id: 0,
        uuid: "",
        companyreviewid: 0,
        //ratename: null,
        ratetype: "",
        rate: 0,
        comments: "",
      },
    ], //additional rating values
    reviewTitle: "",
    pros: "",
    cons: "",
    comments: "",
    suggestions: "",
    clientName: "",
    empStartYear: "",
    empEndYear: "",
    currentEmpStatus: "",
    salary: "",
    empType: "",
    paymentType: "",
    empTitle: "",
    location: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  interface AlertState {
    open: boolean;
    message: string;
    severity: AlertColor;
  }

  const [errors, setErrors] = useState<ReviewErrors>({
    rate: "",
    //ratings: [],
    reviewTitle: "",
    //clientName: "",
    empStartYear: "",
    empEndYear: "",
    currentEmpStatus: "",
    empType: "",
    empTitle: "",
    location: "",
    paymentType: "",
    comments: "",
    pros: "",
    cons: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedCompanyData = useAppSelector(selectedCompany);
  // If user already reviewed this company. Then they can edit it.
  const userCompanyReviewDetails = useAppSelector(userCompanyReview);
  const saveUserReviewStatus = useAppSelector(saveReviewStatus);
  const previousPathRoutes = useAppSelector(previousRoutes);
  const locationData = useAppSelector(locationsList);

  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [snackBarState, setSnackBarState] = React.useState<AlertState>({
    open: false,
    message: "Info",
    severity: "success",
  });

  useEffect(() => {
    if (saveUserReviewStatus === "fulfilled") {
      setSnackBarState({
        ...snackBarState,
        open: true,
        message: "Saved Review. Thank you",
        severity: "success",
      });
      //dispatch(clearPreviousRoutes(""));
      let pathEntity = previousPathRoutes[previousPathRoutes.length - 1];

      navigate(pathEntity.path);
    } else if (saveUserReviewStatus === "error") {
      setSnackBarState({
        ...snackBarState,
        open: true,
        message: "Error saving review. Please try later",
        severity: "error",
      });
    }
    return () => {
      // This cleanup function will run when the component unmounts
      // or when the route changes
      dispatch(clearSaveReview());
    };
  }, [saveUserReviewStatus]);

  useEffect(() => {
    //console.log("locationData", locationData);
    setOptions(
      Array.isArray(locationData.locationdata) ? locationData.locationdata : []
    );
  }, [locationData]);

  useEffect(() => {
    dispatch(getUserCompReviewDetails(selectedCompanyData.uuid));
  }, []);

  useEffect(() => {
    const setCompanyReveiwData = async () => {
      if (
        userCompanyReviewDetails &&
        userCompanyReviewDetails.data &&
        userCompanyReviewDetails.data.length > 0
      ) {
        setFormData({
          uuid: userCompanyReviewDetails.data[0].companyreview_uuid,
          rate: userCompanyReviewDetails.data[0].rate,
          ratings: userCompanyReviewDetails.data[0].reviewRates,
          reviewTitle: userCompanyReviewDetails.data[0].title,
          pros: userCompanyReviewDetails.data[0].pros,
          cons: userCompanyReviewDetails.data[0].cons,
          comments: userCompanyReviewDetails.data[0].comments,
          suggestions: userCompanyReviewDetails.data[0].suggestions,
          clientName: userCompanyReviewDetails.data[0].clientname,
          empStartYear: userCompanyReviewDetails.data[0].startyear,
          empEndYear: userCompanyReviewDetails.data[0].endyear,
          currentEmpStatus: userCompanyReviewDetails.data[0].employmentstatus,
          salary: userCompanyReviewDetails.data[0].salary_range,
          empType: userCompanyReviewDetails.data[0].employmenttype,
          paymentType: userCompanyReviewDetails.data[0].paymenttype,
          empTitle: userCompanyReviewDetails.data[0].employmenttitle,
          location: userCompanyReviewDetails.data[0].location,
        });
      } else {
        setFormData(defaultFormData);
      }
    };
    setCompanyReveiwData();
  }, [userCompanyReviewDetails]);

  // useEffect(() => {
  //   return () => {
  //     // This cleanup function will run when the component unmounts
  //     // or when the route changes
  //     dispatch(clearSaveReview());
  //   };
  // }, []);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "empStartYear" || name === "empEndYear") {
      let val = value.toString();
      setFormData({ ...formData, [name]: val });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setErrors({ ...errors, [name]: "" });
  };

  const handleRatingChange = (event: any, newValue: any) => {
    setFormData({ ...formData, rate: newValue });
    setErrors({ ...errors, rate: "" });
  };

  const handleLocationsChange = (locationText: any) => {
    if (locationText.length >= 3) {
      setFormData({ ...formData, location: locationText });
      dispatch(getLocations(locationText));
    }
    if (locationText === "") {
      setFormData({ ...formData, location: locationText });
    } else {
      setErrors({ ...errors, location: "" });
    }
  };

  const getRatingsValue = (ratetype: string) => {
    const item = formData.ratings.find((data) => data.ratetype === ratetype);
    return item ? Number(item.rate) : 0;
  };

  const handleRatingsRateChange = (
    event: any,
    newValue: any,
    ratetype: string
  ) => {
    // const item = formData.ratings;

    let newValueNumber = Number(newValue);

    if (newValueNumber > 0) {
      const existingIndex = formData.ratings.findIndex(
        (item) => item.ratetype === ratetype
      );
      if (existingIndex !== -1) {
        // Update existing item
        const updatedRating = [...formData.ratings];
        updatedRating[existingIndex] = {
          ...updatedRating[existingIndex],
          rate: newValueNumber,
        };
        setFormData({ ...formData, ratings: updatedRating });
      } else {
        setFormData({
          ...formData,
          ratings: [
            ...formData.ratings,
            {
              id: 0, // assigned during save
              uuid: uuidv4(), // new uuid
              companyreviewid: 0, // assigned during save
              comments: "",
              ratetype: ratetype,
              rate: newValueNumber,
            },
          ],
        });
      }
    }
  };

  const validateForm = () => {
    const newErrors: ReviewErrors = {
      rate: "",
      //ratings: [],
      reviewTitle: "",
      //clientName: "",
      empStartYear: "",
      empEndYear: "",
      currentEmpStatus: "",
      empType: "",
      empTitle: "",
      location: "",
      paymentType: "",
      comments: "",
      pros: "",
      cons: "",
    };
    if (formData.rate === 0) {
      newErrors.rate = "Please select a rating";
    }
    if (!formData.reviewTitle.trim()) {
      newErrors.reviewTitle = "Review title is required";
    }
    if (formData.reviewTitle.trim().length < 10) {
      newErrors.reviewTitle = "Minimum 10 chars are required";
    }
    if (!formData.comments.trim()) {
      newErrors.comments = "Comments are required";
    }
    if (formData.comments.trim().length < 20) {
      newErrors.comments = "Minimum 20 chars are required";
    }
    if (!formData.pros.trim()) {
      newErrors.pros = "Pros are required";
    }
    if (!formData.cons.trim()) {
      newErrors.cons = "Cons are required";
    }
    //if (!formData.clientName.trim())
    //  newErrors.clientName = "Client name is required";
    if (!formData.empStartYear) {
      newErrors.empStartYear = "Start year is required";
    }
    if (!formData.empEndYear) {
      newErrors.empEndYear = "End year is required";
    }
    if (formData.empEndYear < formData.empStartYear) {
      newErrors.empEndYear = "End year cannot be less then start year";
    }
    if (!formData.currentEmpStatus) {
      newErrors.currentEmpStatus = "Current employment status is required";
    }
    if (!formData.empType) {
      newErrors.empType = "Employment type is required";
    }
    if (!formData.empTitle.trim()) {
      newErrors.empTitle = "Employment title is required";
    }
    if (!formData.location.trim()) {
      newErrors.location = "Location is required";
    }
    if (!formData.paymentType.trim()) {
      newErrors.paymentType = "Payment type is required";
    }

    return newErrors;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newErrors = validateForm();

    const checkErrors = Object.values(newErrors).some(
      (value) => value !== null && value !== ""
    );

    if (!checkErrors) {
      let uuid = formData.uuid ? formData.uuid : uuidv4();
      let saveData: CreateReviewInterface = {
        editExistingReview: formData.uuid ? true : false,
        companyreviewUuid: uuid,
        userid: "", // logged in userid
        companyid: selectedCompanyData.uuid,
        overallrate: formData.rate.toString(),
        reviewRates: formData.ratings,
        title: formData.reviewTitle,
        comments: formData.comments,
        pros: formData.pros,
        cons: formData.cons,
        suggestions: formData.suggestions,
        employment_status: formData.currentEmpStatus,
        start_year: formData.empStartYear,
        end_year: formData.empEndYear,
        employment_type: formData.empType,
        payment_type: formData.paymentType,
        hourly_rate: "", //formData.,
        salary_range: formData.salary,
        employment_title: formData.empTitle,
        client_name: formData.clientName,
        location: formData.location,
        lastupdate_by: new Date().toString(),
      };

      dispatch(saveCompanyReview(saveData));
      // Handle form submission here
    } else {
      setErrors(newErrors);
    }
  };

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    //setOpenSnackBar(false);
    setSnackBarState({ ...snackBarState, open: false });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 800, margin: "auto", p: 2 }}
    >
      <CustomSnackbar
        open={snackBarState.open}
        handleClose={handleSnackBarClose}
        message={snackBarState.message}
        severity={snackBarState.severity}
      />
      <Stack
        sx={{
          display: "flex",
          //marginLeft: "20px",
          marginTop: "70px",
        }}
      >
        <CustomBreadCrumbs
          screenType={Constants.screenType.companyReviewEdit}
          companyCode={selectedCompanyData.companycode}
          currentLinkText={"review"}
        />
      </Stack>
      <Typography sx={{ paddingTop: 5 }} variant="h4" gutterBottom>
        {`Submit a Review for ${selectedCompanyData.name}`}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="legend" sx={{ fontWeight: "bold" }}>
            Overall Rating *
          </Typography>
          <Rating
            name="rating"
            sx={{ fontSize: "3rem", color: "#ef5350" }}
            value={formData.rate}
            onChange={handleRatingChange}
            precision={1}
          />
          {errors.rate && <Typography color="error">{errors.rate}</Typography>}
        </Grid>
        {ratingItems.map((item) => (
          <Grid item xs={12} sm={6} key={item.name}>
            <Typography component="legend" sx={{ fontWeight: "bold" }}>
              {item.label}
            </Typography>
            <Rating
              name={item.name}
              value={getRatingsValue(item.rateType)}
              //onChange={handleRatingChange(item.name)}
              //value={formData.ratings}
              onChange={(event, newValue) => {
                handleRatingsRateChange(event, newValue, item.rateType);
              }}
              precision={1}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography component="legend" sx={{ fontWeight: "bold" }}>
            Review Summary *
          </Typography>
          <TextField
            fullWidth
            //label="Review Title"
            name="reviewTitle"
            value={formData.reviewTitle}
            onChange={handleChange}
            error={!!errors.reviewTitle}
            helperText={errors.reviewTitle}
            inputProps={{ minLength: 3 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" sx={{ fontWeight: "bold" }}>
            Review Comments *
          </Typography>
          <TextField
            fullWidth
            //label="Comments"
            name="comments"
            multiline
            rows={3}
            value={formData.comments}
            onChange={handleChange}
            error={!!errors.comments}
            helperText={errors.comments}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Pros *"
            name="pros"
            multiline
            rows={2}
            value={formData.pros}
            onChange={handleChange}
            error={!!errors.pros}
            helperText={errors.pros}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Cons *"
            name="cons"
            multiline
            rows={2}
            value={formData.cons}
            onChange={handleChange}
            error={!!errors.cons}
            helperText={errors.cons}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" sx={{ fontWeight: "bold" }}>
            Suggestions to company
          </Typography>
          <TextField
            fullWidth
            //label="Suggestions"
            name="suggestions"
            multiline
            rows={3}
            value={formData.suggestions}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" sx={{ fontWeight: "bold" }}>
            Employment Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.currentEmpStatus}>
            <InputLabel>Current Employment Status *</InputLabel>
            <Select
              name="currentEmpStatus"
              value={formData.currentEmpStatus}
              onChange={handleChange}
              label="Current Employment Status"
            >
              <MenuItem value="current">Current Employee</MenuItem>
              <MenuItem value="former">Former Employee</MenuItem>
            </Select>
            {errors.currentEmpStatus && (
              <Typography color="error">{errors.currentEmpStatus}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth error={!!errors.empStartYear}>
            <InputLabel>Employment Start Year *</InputLabel>
            <Select
              name="empStartYear"
              value={formData.empStartYear}
              onChange={handleChange}
              label="Employment Start Year"
            >
              <MenuItem value=""></MenuItem>
              {getYearRange(35).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            {errors.empStartYear && (
              <Typography color="error">{errors.empStartYear}</Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth error={!!errors.empEndYear}>
            <InputLabel>Employment End Year *</InputLabel>
            <Select
              name="empEndYear"
              value={formData.empEndYear}
              onChange={handleChange}
              label="Employment End Year"
            >
              <MenuItem value=""></MenuItem>
              {getYearRange(35).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
              <MenuItem value="present">Present</MenuItem>
            </Select>
            {errors.empEndYear && (
              <Typography color="error">{errors.empEndYear}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth error={!!errors.empType}>
            <InputLabel>Employment Type *</InputLabel>
            <Select
              name="empType"
              value={formData.empType}
              onChange={handleChange}
              label="Employment Type"
            >
              <MenuItem value="fulltime">Full-time</MenuItem>
              <MenuItem value="parttime">Part-time</MenuItem>
              <MenuItem value="contract">Contract</MenuItem>
              <MenuItem value="intern">Intern</MenuItem>
            </Select>
            {errors.empType && (
              <Typography color="error">{errors.empType}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth error={!!errors.empType}>
            <InputLabel>Payment Type *</InputLabel>
            <Select
              name="paymentType"
              value={formData.paymentType}
              onChange={handleChange}
              label="Payment Type"
            >
              <MenuItem value="Salary">Salary</MenuItem>
              <MenuItem value="w2">W2</MenuItem>
              <MenuItem value="1099">1099</MenuItem>
              <MenuItem value="Corp-to-Corp">Corp-to-Corp</MenuItem>
            </Select>
            {errors.empType && (
              <Typography color="error">{errors.paymentType}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Job or Contract Title *"
            name="empTitle"
            value={formData.empTitle}
            onChange={handleChange}
            error={!!errors.empTitle}
            helperText={errors.empTitle}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Client Name (contract)"
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            // error={!!errors.clientName}
            // helperText={errors.clientName}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            label="Salary"
            name="salary"
            value={formData.salary}
            onChange={handleChange}
          />
        </Grid> */}

        {/* <Grid item xs={12}>
          <Typography component="legend" sx={{ fontWeight: "bold" }}>
            Location
          </Typography>
        </Grid> */}

        <Grid item xs={12}>
          <Typography
            component="legend"
            sx={{ fontWeight: "bold", paddingBottom: "10px" }}
          >
            Location *
          </Typography>
          <Autocomplete
            options={options}
            loading={loading}
            value={formData.location}
            //defaultValue={formData.location ? formData.location : ""}
            //onSelect={handleChange}
            filterOptions={(x) => x} // Disable built-in filtering to rely on API
            onInputChange={(event, newInputValue) =>
              handleLocationsChange(newInputValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by city name"
                name="location"
                value={formData.location}
                error={!!errors.location}
                helperText={errors.location}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <span>Loading...</span> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          {/* <TextField
            fullWidth
            label="Location *"
            name="location"
            value={formData.location}
            onChange={handleChange}
            error={!!errors.location}
            helperText={errors.location}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              //marginTop: "15px",
              //paddingTop: "15px",
              textAlign: "center",
            }}
          >
            <Typography variant="caption" gutterBottom>
              By proceeding, you agree to our
            </Typography>
            <br />

            <Typography variant="caption" gutterBottom>
              <CustomLink
                //onClick={handleClose}
                underline="hover"
                key="1"
                color="#7986cb"
                to={"/terms"}
              >
                Terms of use
              </CustomLink>{" "}
              and{" "}
              <CustomLink
                //onClick={handleClose}
                underline="hover"
                key="2"
                color="#7986cb"
                to={"/privacy"}
              >
                Privacy Policy
              </CustomLink>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ backgroundColor: "black" }}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Submit Review
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyNewReview;
