import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "./store";
import {
  getCompanyList,
  getSelectedCompanyDetails,
  saveNewCompany,
  saveNewReview,
  getUserCompanyReview,
  checkCompanyNameExists,
  getLocationsList,
} from "./api/coreAPI";
//import { Interface } from "readline";
import {
  GetCompanyInterface,
  GetCompanyDetailsInterface,
  CreateCompanyInterface,
} from "../interfaces/company.interfaces";
import { CreateReviewInterface } from "../interfaces/review.interfaces";

export interface StateInterface {
  coreStatus: "" | "fulfilled" | "success" | "loading" | "failed" | "error";
  saveReviewStatus:
    | ""
    | "fulfilled"
    | "success"
    | "saving"
    | "failed"
    | "error";
  // saveCompanyStatus:
  //   ""
  //   | "fulfilled"
  //   | "success"
  //   | "saving"
  //   | "failed"
  //   | "error";
  saveCompanyStatus: {
    status: "" | "fulfilled" | "success" | "saving" | "failed" | "error";
    apiCode?: "" | string;
    message?: "" | string;
    validationCode: "";
  };
  coreMessage: string;
  companyList: {};
  userCompanyReview: {};
  companySaved: {};
  checkCompany: {};
  locationsList: [];
  selectedCompany: any;
  companyDetails: any;
}

const initialState: StateInterface = {
  coreStatus: "loading",
  saveReviewStatus: "saving",
  coreMessage: "",
  companyList: {},
  selectedCompany: {},
  companyDetails: {},
  userCompanyReview: {},
  saveCompanyStatus: {
    status: "",
    apiCode: "",
    message: "",
    validationCode: "",
  },
  companySaved: {},
  checkCompany: {},
  locationsList: [],
};

export const getCompanies = createAsyncThunk(
  "company/companies",
  async (data: GetCompanyInterface) => {
    const response = await getCompanyList(data);
    return response.data;
  }
);

// Create and Update Company.
export const saveCompany = createAsyncThunk(
  "company/save",
  async (data: CreateCompanyInterface) => {
    const response = await saveNewCompany(data);
    return response.data;
  }
);

export const checkCompanyExists = createAsyncThunk(
  "company/exists",
  async (data: string) => {
    const response = await checkCompanyNameExists(data);
    return response.data;
  }
);

export const getLocations = createAsyncThunk(
  "company/locations",
  async (data: string) => {
    const response = await getLocationsList(data);
    return response.data;
  }
);

export const getCompanyDetails = createAsyncThunk(
  "company/details",
  async (data: GetCompanyDetailsInterface) => {
    const response = await getSelectedCompanyDetails(data);
    return response.data;
  }
);

export const saveCompanyReview = createAsyncThunk(
  "company/reviewsave",
  async (data: CreateReviewInterface) => {
    const response = await saveNewReview(data);
    return response.data;
  }
);

export const getUserCompReviewDetails = createAsyncThunk(
  "company/user/review",
  async (company_id: string) => {
    const response = await getUserCompanyReview(company_id);
    return response.data;
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = {};
    },
    clearCompany: (state) => {
      state.coreStatus = "";
      state.coreMessage = "";
      state.companyList = {};
    },
    clearCompanyDetails: (state) => {
      state.coreStatus = "";
      state.coreMessage = "";
      state.companyDetails = {};
    },
    clearSaveReview: (state) => {
      state.saveReviewStatus = "";
    },
    clearSaveCompany: (state) => {
      state.saveCompanyStatus = {
        status: "",
        apiCode: "",
        message: "",
        validationCode: "",
      };
    },
    clearCheckCompany: (state) => {
      state.checkCompany = {};
    },
    clearLocationListCompany: (state) => {
      state.locationsList = [];
    },
    clearUserCompaneyReview: (state) => {
      state.userCompanyReview = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.coreStatus = "loading";
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.coreStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.coreStatus = "error";
        } else {
          state.companyList = action.payload;
        }
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.coreStatus = "error";
        state.coreMessage = action.error.message || "";
      })
      .addCase(getCompanyDetails.pending, (state) => {
        state.coreStatus = "loading";
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.coreStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.coreStatus = "error";
        } else {
          state.companyDetails = action.payload;
        }
      })
      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.coreStatus = "error";
        state.coreMessage = action.error.message || "";
      })
      // Check if company exists
      .addCase(checkCompanyExists.pending, (state) => {
        state.coreStatus = "loading";
      })
      .addCase(checkCompanyExists.fulfilled, (state, action) => {
        state.coreStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.coreStatus = "error";
        } else {
          state.checkCompany = action.payload;
        }
      })
      .addCase(checkCompanyExists.rejected, (state, action) => {
        state.coreStatus = "error";
        state.coreMessage = action.error.message || "";
      })
      // Save Company
      .addCase(saveCompany.pending, (state) => {
        state.saveCompanyStatus.status = "saving";
      })
      .addCase(saveCompany.fulfilled, (state, action) => {
        state.saveCompanyStatus.status = "fulfilled";

        //console.log("action.payload", action.payload);
        if (
          action.payload.status === 400 ||
          action.payload.status === 404 ||
          action.payload.status === 409 ||
          action.payload.status === 500 ||
          action.payload.statusCode === 400 ||
          action.payload.statusCode === 404 ||
          action.payload.statusCode === 409 ||
          action.payload.statusCode === 500
        ) {
          state.saveCompanyStatus.status = "error";
          state.saveCompanyStatus.apiCode = action.payload.status;
          state.saveCompanyStatus.validationCode = action.payload.statusCode;
          state.saveCompanyStatus.message = action.payload.message;
        } else {
          state.companySaved = action.payload;
        }
      })
      .addCase(saveCompany.rejected, (state, action) => {
        //console.log("action.payload ERROR", action.error);
        //console.log("action.payload ERROR", action);

        state.saveCompanyStatus.status = "error";
        //state.saveCompanyStatus.validationCode = "";
        state.saveCompanyStatus.message = action.error.message
          ? action.error.message
          : "";
        state.saveCompanyStatus.apiCode = action.error.code;
      })
      // Get Locations
      .addCase(getLocations.pending, (state) => {
        state.coreStatus = "loading";
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.coreStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.coreStatus = "error";
        } else {
          state.locationsList = action.payload;
        }
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.coreStatus = "error";
        state.coreMessage = action.error.message || "";
      })
      .addCase(saveCompanyReview.pending, (state) => {
        state.saveReviewStatus = "saving";
      })
      .addCase(saveCompanyReview.fulfilled, (state, action) => {
        state.saveReviewStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.saveReviewStatus = "error";
        } else {
          state.companyDetails = action.payload;
        }
      })
      .addCase(saveCompanyReview.rejected, (state, action) => {
        state.saveReviewStatus = "error";
        state.coreMessage = action.error.message || "";
      })
      .addCase(getUserCompReviewDetails.pending, (state) => {
        state.coreStatus = "loading";
      })
      .addCase(getUserCompReviewDetails.fulfilled, (state, action) => {
        state.coreStatus = "fulfilled";

        if (action.payload.status === 404) {
          state.coreStatus = "error";
        } else {
          state.userCompanyReview = action.payload;
        }
      })
      .addCase(getUserCompReviewDetails.rejected, (state, action) => {
        state.coreStatus = "error";
        state.coreMessage = action.error.message || "";
      });
  },
});

export const {
  clearCompany,
  clearCompanyDetails,
  setSelectedCompany,
  clearSelectedCompany,
  clearSaveReview,
  clearSaveCompany,
  clearCheckCompany,
  clearLocationListCompany,
} = companySlice.actions;

export const selectedCompany = (state: RootState) =>
  state.company.selectedCompany;
export const saveReviewStatus = (state: RootState) =>
  state.company.saveReviewStatus;
export const coreStatus = (state: RootState) => state.company.coreStatus;
export const coreMessage = (state: RootState) => state.company.coreMessage;
export const companyList = (state: RootState) => state.company.companyList;
export const companyDetails = (state: RootState) =>
  state.company.companyDetails;
export const userCompanyReview = (state: RootState) =>
  state.company.userCompanyReview;
export const saveCompanyStatus = (state: RootState) =>
  state.company.saveCompanyStatus;
export const companySaved = (state: RootState) => state.company.companySaved;
export const checkCompany = (state: RootState) => state.company.checkCompany;
export const locationsList = (state: RootState) => state.company.locationsList;
export default companySlice.reducer;
