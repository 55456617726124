import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Config from "../../config/Config";
import Constants from "../../config/Constants";
import CustomLink from "../CustomLink";

const componentStyles = {
  loginButton: {
    borderRadius: "5px",
    color: "black",
    borderColor: "black",
    width: {
      xs: 250,
      sm: 250,
      md: 250,
      lg: 250,
    },
  },
  titleText: {
    color: "black",
    fontSize: "0.8rem",
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    paddingBottom: 50,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function LoginDialog(props: any) {
  const { openDialog, onDialogClose } = props;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    try {
      setOpen(openDialog);
    } catch (e) {}
  }, [openDialog]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onDialogClose(false);
  };

  const handleGoogleLogin = () => {
    localStorage.setItem(Constants.localStorageKeys.loginType, "google");
    window.location.href = Config().envConfigModule.serverAPIGoogleRouteUrl; // "http://localhost:3000/auth/google";
  };

  const handleLinkedInLogin = () => {
    localStorage.setItem(Constants.localStorageKeys.loginType, "linkedin");
    window.location.href = Config().envConfigModule.serverAPILinkedInRouteUrl; // "http://localhost:3000/auth/linkedin";
  };

  return (
    // <React.Fragment>
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={false}
      maxWidth={"xs"}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Sign In
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: "black",
          //color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <DialogContentText sx={componentStyles.titleText}>
          You can sign-in using your Google or LinkedIn account.
        </DialogContentText>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        >
          <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel htmlFor="max-width"></InputLabel>
            <Button
              variant="outlined"
              startIcon={<GoogleIcon />}
              sx={componentStyles.loginButton}
              onClick={handleGoogleLogin}
            >
              Continue with Google
            </Button>
          </FormControl>
          <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel htmlFor="max-width"></InputLabel>
            <Button
              variant="outlined"
              startIcon={<LinkedInIcon />}
              sx={componentStyles.loginButton}
              onClick={handleLinkedInLogin}
            >
              Continue with LinkedIn
            </Button>
          </FormControl>
        </Box>
        <Box
          sx={{
            //marginTop: "15px",
            paddingTop: "15px",
            textAlign: "center",
          }}
        >
          <Typography variant="caption" gutterBottom>
            By proceeding, you agree to our
          </Typography>
          <br />

          <Typography variant="caption" gutterBottom>
            <CustomLink
              onClick={handleClose}
              underline="hover"
              key="1"
              color="#7986cb"
              to={"/terms"}
            >
              Terms of use
            </CustomLink>{" "}
            and{" "}
            <CustomLink
              onClick={handleClose}
              underline="hover"
              key="2"
              color="#7986cb"
              to={"/privacy"}
            >
              Privacy Policy
            </CustomLink>
          </Typography>
        </Box>
      </DialogContent>
      {/* <DialogActions></DialogActions> */}
    </BootstrapDialog>
    // </React.Fragment>
  );
}
