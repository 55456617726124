const Contants = {
  drawerWidth: 240,
  emailRegEx: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  errors: {
    UserDoesNotExist:
      "User does not exist or username and password dont match.",
  },
  productName: "QRADE",
  validations: {
    signUp: {
      firstName: "Please enter first name",
      lastName: "Please enter last name",
      email: "Please enter a valid email address",
      password: "Password cannot be less than 8 characters",
      retypePwd: "Password does not match",
      agreement:
        "Please read and accept the Terms of Service and Privacy Policy",
    },
  },
  localStorageKeys: {
    loginType: "login-type",
    accessToken: "access-token",
    refreshToken: "refresh-token",
    accessTokenExpire: "access-token-exp-time",
  },
  screenType: {
    home: "home",
    companyHome: "company-home", // Current Home
    companyDetails: "company-details", // Company Details
    companyReviewEdit: "company-review-edit", // New and Edit
    companyEdit: "company-edit", // Edit
    companyNew: "company-new", // New
    privacy: "privacy",
    terms: "terms",
  },
  status: {
    loading: "loading",
    saving: "saving",
    fulfilled: "fulfilled",
    success: "success",
    error: "error",
  },
};

export default Contants;
