export default function Config() {
  const env = process.env.REACT_APP_ENV || "localhost";
  let envConfigModule;
  switch (env) {
    case "localhost":
      envConfigModule = {
        serverAPI: "http://localhost:3000",
        serverAPIGoogleRouteUrl: "http://localhost:3000/auth/google",
        serverAPILinkedInRouteUrl: "http://localhost:3000/auth/linkedin",
        websiteUrl: "http://localhost:3001",
      };
      break;
    case "dev":
      envConfigModule = {
        serverAPI: "http://localhost:3000",
        serverAPIGoogleRouteUrl: "http://localhost:3000/auth/google",
        serverAPILinkedInRouteUrl: "http://localhost:3000/auth/linkedin",
        websiteUrl: "http://localhost:3001",
      };
      break;
    case "prod":
      envConfigModule = {
        serverAPI: "https://api.qrade.co",
        serverAPIGoogleRouteUrl: "https://api.qrade.co/auth/google",
        serverAPILinkedInRouteUrl: "https://api.qrade.co/auth/linkedin",
        websiteUrl: "https://www.qrade.co",
      };
      break;
    default:
      envConfigModule = {
        serverAPI: "http://localhost:3000",
        serverAPIGoogleRouteUrl: "http://localhost:3000/auth/google",
        serverAPILinkedInRouteUrl: "http://localhost:3000/auth/linkedin",
        websiteUrl: "http://localhost:3001",
      };
  }
  return {
    appPrefix: process.env.REACT_APP_PREFIX,
    apiVersion: process.env.REACT_APP_API_VERSION || "v1",
    clientKey: process.env.REACT_APP_API_CLIENTKEY || "unknown",
    clientID: process.env.REACT_APP_ID || "unknown",
    envConfigModule: envConfigModule,
  };
}
